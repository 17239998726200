import React, { useState } from 'react';
import {
    Card,
    Stack,
    Typography,
    TextField,
    Tabs,
    Tab,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Services from 'src/services/Services';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toast } from 'react-toastify';
import moment from 'moment';
import DynamicTable from 'src/components/shared/DynamicTable';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';

export default function LogSearch() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [barcode, setBarcode] = useState('');
    const [keyword, setKeyword] = useState('');
    const [phone, setPhone] = useState('');
    const [logs, setLogs] = useState([]);
    const [activeTab, setActiveTab] = useState(0); // Varsayılan olarak "Handover" sekmesi açık

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        handleClear(); // Tab değiştiğinde filtreleri sıfırla
    };

    const formatValue = (value) => {
        if (value === null || value === undefined || value === '') {
            return 'null';
        } else if (value === false) {
            return 'false';
        }
        return value;
    };

    const formatLog = (log) => {
        if (log === null || log === undefined || log === '') {
            return 'null';
        } else if (log === false) {
            return 'false';
        }
        return log.replace(/\\"/g, '"');
    }


    const formatName = (value) => {
        if (value === null || value === undefined || value === '') {
            return 'null';
        } else if (value === false) {
            return 'false';
        }
        return value.name + " " + value.lastname;
    };

    const convertTimestampToDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const TABLE_HEAD = [
        { id: 'id', label: "ID", format: formatValue, alignRight: false },
        {
            id: 'userID',
            label: "Oluşturan",
            format: (value) => formatName(value),
            sortBy: "userID.name",
            alignRight: false
        },
        {
            id: 'createdDateTime',
            label: "Oluşturulma Tarihi",
            format: (value) => value ? convertTimestampToDate(value) : 'null',
            alignRight: false
        },
        {
            id: 'log',
            label: "Log",
            format: (value) => formatLog(JSON.stringify(value)),
            alignRight: false,
        },

    ];

    const handleSubmit = () => {
        setIsBackdropOpen(true);
        const formattedStartDate = startDate ? moment(startDate).format('DD-MM-YYYY') : '';
        const formattedEndDate = endDate ? moment(endDate).format('DD-MM-YYYY') : '';

        Services.GetHandoverLogsSearch(parsedUser.token, barcode, phone, "", formattedStartDate, formattedEndDate, keyword)
            .then((res) => {
                if (res.data.status) {
                    setLogs(res.data.result);
                } else {
                    toast.error("Filtrelemeye göre log bulunamadı.");
                }
                setIsBackdropOpen(false);
            })
            .catch(() => {
                toast.error("Bir hata oluştu.");
                setIsBackdropOpen(false);
            });
    };

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setBarcode('');
        setKeyword('');
        setPhone('');
        setLogs([]);
    };

    return (
        <>
            <Helmet>
                <title>Log Arama | Mall Logistics Panel</title>
            </Helmet>
            <Stack style={{ paddingInline: '25px' }}>
                <Backdrop sx={{ color: '#fff', zIndex: 2, height: '100%' }} open={isBackdropOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Log Arama
                    </Typography>
                </Stack>

                {/* Tab Menüsü */}
                <Card style={{ padding: '10px', marginBottom: '20px' }}>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab label="Handover" />
                        {/* <Tab label="Diğer Loglar" /> */}
                    </Tabs>
                </Card>

                {/* Aktif olan tabın içeriği */}
                <Card style={{ padding: '25px' }}>
                    {activeTab === 0 && (
                        <Stack direction="row" spacing={2} alignItems={'center'}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Başlangıç Tarihi"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    maxDate={endDate}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Bitiş Tarihi"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    minDate={startDate}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>

                            <TextField id="phone" label="Telefon" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <TextField id="barcode" label="Barkod" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                            <TextField id="keyword" label="Anahtar Kelime" value={keyword} onChange={(e) => setKeyword(e.target.value)} />

                            <FilterButton onClick={handleSubmit} label={"ARA"} />
                            <ClearButton onClick={handleClear} />
                        </Stack>
                    )}
                    {/* Tab içeriği değiştirilebilir olacak */}
                    {activeTab === 0 && (
                        <DynamicTable
                            data={logs}
                            headers={TABLE_HEAD}
                            initialOrder={'createdDateTime'}
                            initalOrderDirection={'desc'}
                            isOrdered={true}
                        />
                    )}

                    {activeTab === 1 && (
                        <Typography sx={{ mt: 3 }}>Diğer log türleri için içerik buraya eklenecek.</Typography>
                    )}
                </Card>
            </Stack>
        </>
    );
}
