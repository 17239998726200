import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  RadioGroup,
  Switch,
  FormControlLabel,
  Typography,
  DialogActions,
  Radio,
  InputLabel,
  Stack,
  Button,
  OutlinedInput,
  Select,
  TextField,
  MenuItem,
  Chip,
  DialogContentText,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import api from 'src/services/BaseService';
import CompleteButton from '../shared/CompleteButton';
import { getUserTypeLabel } from 'src/utils/getUserLabel';
import { useLocalization } from 'src/context/LocalizationContext';

const AddEditUserModal = ({
  dialogOpen,
  setDialogOpen,
  modalType,
  user,
  malls,
  setUser,
  getUserList,
}) => {
  const { language } = useLocalization();

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    password: '',
    email: '',
    phone: '',
    username: '',
    frontAuth: '',
    type: 'user',
  });
  const [selectpass, setSelectPass] = useState('');
  const [selectedMalls, setSelectedMalls] = useState([]);
  const [storeNone, setStoreNone] = useState(true);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [userActive, setUserActive] = useState(true);
  const [canCancel, setCanCancel] = useState(false);
  const [isResetPassButtonDisabled, setIsResetPassButtonDisabled] =
    useState(false);
  const [dialogPasswordOpen, setDialogPasswordOpen] = useState(false);

  const handleChangeUserType = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      type: value,
    }));
    if (value === 'corporational' || value === 'user') {
      setStoreNone(true);
      setSelectedMalls([]);
    } else if (value === 'highadmin') {
      setSelectedBranches([]);
      setStoreNone(false);
      setSelectedMalls(malls);
    } else {
      setSelectedBranches([]);
      setStoreNone(false);
      setSelectedMalls([]);
    }
  };

  const handleDialogPasswordOpen = () => {
    setDialogPasswordOpen(true);
  };

  const handleDialogPasswordClose = () => {
    setDialogPasswordOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'selectedBranch') {
      setSelectedBranches(value);
    }
  };

  const getBranches = async (mallIds) => {
    const uniqueMallIds = Array.from(new Set(mallIds));
    const allBranches = [];

    try {
      const requests = uniqueMallIds.map(async (mallId) => {
        const response = await api.post('flow/get-malls-branches', {
          mallID: mallId,
          withLocations: false,
        });

        if (response.data.status) {
          return response.data.result;
        } else {
          console.log(response.data.message);
          return [];
        }
      });

      const mallBranchesArray = await Promise.all(requests);

      mallBranchesArray.forEach((mallBranches) => {
        allBranches.push(...mallBranches);
      });

      const uniqueBranches = allBranches.filter(
        (branch, index, self) =>
          index === self.findIndex((b) => b.branchID === branch.branchID)
      );

      // console.log('uniqueBranches: ', uniqueBranches);
      setBranches(uniqueBranches);
    } catch (error) {
      console.log(error);
    }
  };

  const getBranchesByID = async (mallId) => {
    try {
      // API çağrısı yapılıyor
      const response = await api.post('flow/get-malls-branches', {
        mallID: mallId,
        withLocations: false,
      });

      if (response.data.status) {
        // Gelen branch'leri alıyoruz
        const newBranches = response.data.result;

        // State'teki mevcut branch'leri alıyoruz ve yeni gelen branch'lerle birleştiriyoruz
        setBranches((prevBranches) => {
          // Mevcut ve yeni branch'leri birleştiriyoruz
          const allBranches = [...prevBranches, ...newBranches];

          // Unique (benzersiz) branch'leri elde etmek için filtreleme
          const uniqueBranches = allBranches.filter(
            (branch, index, self) =>
              index === self.findIndex((b) => b.branchID === branch.branchID)
          );

          return uniqueBranches;
        });
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMallChange = async (e) => {
    const { name, value } = e.target;
    const previousSelection = selectedMalls || [];

    const mergedSelection = [
      ...previousSelection,
      ...value.filter((item) => !previousSelection.includes(item)),
    ];
    console.log(mergedSelection, 'Selected');

    setSelectedMalls(mergedSelection);
    // getBranches(mergedSelection?.map((mall) => mall.id));
    getBranchesByID(value[value.length - 1].id);
  };

  const handleSetUserInactive = (isActive) => {
    const userID = user?.id;
    if (userID) {
      const endpoint = isActive
        ? 'flow/user-set-active'
        : 'flow/user-set-passive';

      api
        .post(endpoint, { userID })
        .then((response) => {
          if (response.data.status) {
            toast.success(
              `Kullanıcı başarıyla ${
                isActive ? 'aktif' : 'pasif'
              } hale getirildi.`
            );
            setUserActive(isActive);
            setTimeout(() => {
              document
                .getElementById('userSwitch')
                .scrollIntoView({ behavior: 'smooth' });
              setTimeout(() => {
                window.location.reload();
              }, 1200);
            }, 1000);
          } else {
            toast.error(
              `Kullanıcı ${
                isActive ? 'aktif' : 'pasif'
              } hale getirilirken bir hata oluştu.`
            );
          }
        })
        .catch((error) => {
          console.error('API Hatası:', error);
          toast.error(
            `Kullanıcı ${
              isActive ? 'aktif' : 'pasif'
            } hale getirilirken bir hata oluştu.`
          );
        });
    } else {
      toast.error('Lütfen bir kullanıcı seçin.');
    }
  };

  const handleSwitchChange = () => {
    setCanCancel(!canCancel);
    if (!canCancel) {
      setSelectedRole([...selectedRole, 'can-cancel']);
    } else {
      setSelectedRole(selectedRole.filter((role) => role !== 'can-cancel'));
    }
  };

  const handleRoleChange = (role) => {
    setSelectedRole((prevRoles) => {
      if (prevRoles?.includes(role)) {
        return prevRoles?.filter((r) => r !== role);
      } else {
        return [...prevRoles, role];
      }
    });
  };

  const handleRemoveMall = (_mall) => {
    console.log(_mall);
    const remainingMalls = selectedMalls.filter((mall) => mall !== _mall);
    const remainingBranches = branches.filter(
      (branch) => branch.mallID !== _mall.id
    );

    const remainingSelectedBranches = [];

    for (let i = 0; i < selectedBranches?.length; i++) {
      console.log(remainingBranches, selectedBranches[i]);
      if (
        remainingBranches.filter(
          (branch) => branch.branchID === selectedBranches[i]
        ).length > 0
      ) {
        remainingSelectedBranches.push(selectedBranches[i]);
      }
    }

    setSelectedMalls(remainingMalls);
    setBranches(remainingBranches);
    setSelectedBranches(remainingSelectedBranches);
  };

  const handleRemoveBranch = (branchID) => {
    if (selectedBranches?.length === 1) {
      setSelectedBranches([]);
    } else {
      const remainingBranches = selectedBranches.filter(
        (id) => id !== branchID
      );
      setSelectedBranches(remainingBranches);
    }
  };

  const handleComplete = () => {
    if (modalType === 'new') {
      if (
        formData?.name?.length >= 2 &&
        formData?.surname?.length >= 2 &&
        formData?.email?.length > 2 &&
        formData?.phone?.length > 2 &&
        formData?.password?.length > 2 &&
        selectedRole.length >= 1 &&
        ((formData.type === 'corporational' && selectedBranches?.length > 0) ||
          (formData.type !== 'corporational' && selectedMalls?.length > 0))
      ) {
        if (formData.password === selectpass) {
          if (formData.type === 'corporational') {
            console.log({
              branchID: selectedBranches[0],
              mallID: selectedMalls[0].id,
              email: formData.email,
              name: formData.name,
              type: formData.type,
              lastname: formData.surname,
              phone: formData.phone,
              username: formData.username,
              password: formData.password,
              isAdministrator: selectedRole.includes('manager'),
              accountCategory: 'store',
              frontAuth: selectedRole,
              authLocations: {
                branch: selectedBranches,
              },
            });
            api
              .post('flow/user-create', {
                branchID: selectedBranches[0],
                mallID: selectedMalls[0].id,
                email: formData.email,
                name: formData.name,
                type: formData.type,
                lastname: formData.surname,
                phone: formData.phone,
                username: formData.username,
                password: formData.password,
                isAdministrator: selectedRole.includes('manager'),
                accountCategory: 'store',
                frontAuth: selectedRole,
                authLocations: {
                  branch: selectedBranches,
                },
              })
              .then((response) => {
                console.log(response.data, 'Data');
                if (response.data.status) {
                  toast.success('Kullanıcı başarıyla oluşturuldu.');
                  getUserList('1');
                  handleCloseDialog();
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {
                toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
              });
          } else {
            let allPoints = [];
            for (let i = 0; i < selectedMalls?.length; i++) {
              for (let j = 0; j < selectedMalls[i].offices?.length; j++) {
                if (j === 0 && i === 0) {
                  allPoints.push({
                    id: selectedMalls[i].offices[j].id,
                    isFirstResponsible: true,
                  });
                } else {
                  allPoints.push({
                    id: selectedMalls[i].offices[j].id,
                    isFirstResponsible: false,
                  });
                }
              }
            }
            // console.log({
            //   mallID: selectedMalls[0].id,
            //   allowedPoints: allPoints,
            //   email: formData.email,
            //   name: formData.name,
            //   type: formData.type,
            //   lastname: formData.surname,
            //   phone: formData.phone,
            //   username: formData.username,
            //   password: formData.password,
            //   isAdministrator: selectedRole.includes('manager'),
            //   accountCategory: 'mall-logistics',
            //   frontAuth: selectedRole,
            //   authLocations: {
            //     mall: selectedMalls?.map((mall) => mall.id),
            //   },
            // });
            api
              .post('flow/users-create-employee', {
                mallID: selectedMalls[0].id,
                allowedPoints: allPoints,
                email: formData.email,
                name: formData.name,
                type: formData.type,
                lastname: formData.surname,
                phone: formData.phone,
                username: formData.username,
                password: formData.password,
                isAdministrator: selectedRole.includes('manager'),
                accountCategory: 'mall-logistics',
                frontAuth: selectedRole,
                authLocations: {
                  mall: selectedMalls?.map((mall) => mall.id),
                },
              })
              .then((response) => {
                console.log(
                  'allowedPoints new: ',
                  allPoints,
                  response.data,
                  'Data'
                );
                if (response.data.status) {
                  toast.success('Kullanıcı başarıyla oluşturuldu.');
                  getUserList('1');
                  handleCloseDialog();
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {
                toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
              });
          }
        } else {
          toast.error('Şifreler eşleşmiyor. Lütfen tekrar deneyin.');
        }
      } else {
        toast.error('Zorunlu alanları doldurunuz...');
      }
    } else {
      {
        if (
          formData?.name?.length >= 2 &&
          formData?.surname?.length >= 2 &&
          formData?.email?.length > 2 &&
          formData?.phone?.length > 2 &&
          selectedRole.length >= 1 &&
          ((formData.type === 'corporational' &&
            selectedBranches?.length > 0) ||
            (formData.type !== 'corporational' && selectedMalls?.length > 0))
        ) {
          if (formData.type === 'corporational') {
            console.log({
              userID: user.id,
              branchID: selectedBranches[0],
              mallID: selectedMalls[0].id,
              email: formData.email,
              name: formData.name,
              lastname: formData.surname,
              phone: formData.phone,
              username: formData.username,
              isAdministrator: selectedRole.includes('manager'),
              accountCategory: 'store',
              frontAuth: selectedRole,
              authLocations: {
                branch: selectedBranches,
              },
            });
            api
              .post('users/update-profile', {
                userID: user.id,
                branchID: selectedBranches[0],
                mallID: selectedMalls[0].id,
                email: formData.email,
                name: formData.name,
                lastname: formData.surname,
                phone: formData.phone,
                username: formData.username,
                isAdministrator: selectedRole.includes('manager'),
                accountCategory: 'store',
                frontAuth: selectedRole,
                authLocations: {
                  branch: selectedBranches,
                },
              })
              .then((response) => {
                console.log(response.data, 'Data');
                if (response.data.status) {
                  toast.success('Kullanıcı başarıyla güncellendi.');
                  getUserList('1');
                  handleCloseDialog();
                  // window.location.reload();
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {
                toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
              });
          } else {
            let allPoints = [];
            for (let i = 0; i < selectedMalls?.length; i++) {
              for (let j = 0; j < selectedMalls[i].offices?.length; j++) {
                if (j === 0 && i === 0) {
                  allPoints.push({
                    id: selectedMalls[i].offices[j].id,
                    isFirstResponsible: true,
                  });
                } else {
                  allPoints.push({
                    id: selectedMalls[i].offices[j].id,
                    isFirstResponsible: false,
                  });
                }
              }
            }
            // console.log({
            //   userID: user.id,
            //   mallID: selectedMalls[0].id,
            //   allowedPoints: allPoints,
            //   email: formData.email,
            //   name: formData.name,
            //   lastname: formData.surname,
            //   phone: formData.phone,
            //   username: formData.username,
            //   isAdministrator: selectedRole.includes('manager'),
            //   accountCategory: 'mall-logistics',
            //   frontAuth: selectedRole,
            //   authLocations: {
            //     mall: selectedMalls?.map((mall) => mall.id),
            //   },
            // });
            api
              .post('users/update-profile', {
                userID: user.id,
                mallID: selectedMalls[0].id,
                allowedPoints: allPoints,
                email: formData.email,
                name: formData.name,
                lastname: formData.surname,
                phone: formData.phone,
                username: formData.username,
                isAdministrator: selectedRole.includes('manager'),
                accountCategory: 'mall-logistics',
                frontAuth: selectedRole,
                authLocations: {
                  mall: selectedMalls?.map((mall) => mall.id),
                },
              })
              .then((response) => {
                console.log(response.data, 'Data');
                console.log({
                  userID: user.id,
                  allowedPoints: allPoints,
                });
                api
                  .post('flow/users-create-employee-allowed-points', {
                    userID: user.id,
                    allowedPoints: allPoints,
                  })
                  .then((res) => {
                    console.log(
                      'allowedPoints:',
                      allPoints,
                      'res.data:',
                      res.data
                    );
                    if (res.data.status) {
                      toast.success('Kullanıcı başarıyla güncellendi.');
                      getUserList('1');
                      handleCloseDialog();
                    } else {
                      toast.error(response.data.message);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error('AVM noktaları tanımlanırken bir hata oluştu.');
                  });
              })
              .catch((error) => {
                toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
              });
          }
        } else {
          toast.error('Zorunlu alanları doldurunuz...');
        }
      }
    }
  };

  const handleResetPassword = () => {
    setIsResetPassButtonDisabled(true);
    api
      .post('flow/user-forced-reset-password', {
        userID: user.id,
      })
      .then((response) => {
        if (response.data) {
          setFormData({
            ...formData,
            password: response.data.result,
          });
          setIsResetPassButtonDisabled(false);
          handleDialogPasswordOpen();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleCopyPassword = () => {
    navigator.clipboard
      .writeText(formData.password)
      .then(() => {
        // // console.log('Şifre kopyalandı:', formData.password);
      })
      .catch((err) => {
        console.error('Şifre kopyalanırken bir hata oluştu:', err);
      });
  };

  useEffect(() => {
    if (dialogOpen) {
      if (modalType === 'edit') {
        setFormData({
          name: user.name,
          surname: user.lastname,
          email: user.email,
          phone: user.phone,
          username: user.username,
          frontAuth: user.frontAuth,
          type: user.type,
        });

        setUserActive(user.status !== '0');
        setSelectedRole(user.frontAuth);
        if (user.type !== 'corporational')
          setCanCancel(user.frontAuth.includes('can-cancel'));

        if (user.type === 'corporational') {
          api
            .post('flow/get-user-auth-locations', {
              userID: user.id,
            })
            .then((res) => {
              console.log(
                'flow/get-user-auth-locations response:',
                res.data.result
              );
              const mallIDs = res.data.result.branch?.map(
                (branch) => branch.mallID
              );
              let uniqueMallIDs = [...new Set(mallIDs)];
              let userMallsArr = [];
              console.log('mallIDs: ', mallIDs);
              for (let i = 0; i < uniqueMallIDs.length; i++) {
                const userMalls = malls.find(
                  (mall) => mall.id === uniqueMallIDs[i]
                );
                userMallsArr.push(userMalls);
              }

              console.log('userMallsArr: ', userMallsArr);

              setSelectedMalls(userMallsArr);
              getBranches(userMallsArr?.map((mall) => mall.id));

              let userBranches = [];
              for (let i = 0; i < user.authLocations?.branch?.length; i++) {
                userBranches.push(user.authLocations?.branch[i]);
              }
              console.log('userBranches: ', userBranches);
              setSelectedBranches(userBranches);
            })
            .catch((err) => {
              setSelectedMalls([]);
              setSelectedBranches([]);
            });
        } else {
          api
            .post('flow/get-user-auth-locations', {
              userID: user.id,
            })
            .then((res) => {
              const mallIDs = res?.data?.result?.mall?.map((item) => item.id);
              let uniqueMallIDs = [...new Set(mallIDs)];
              let userMallsArr = [];

              for (let i = 0; i < uniqueMallIDs?.length; i++) {
                const userMalls = malls.find(
                  (mall) => mall.id === uniqueMallIDs[i]
                );
                userMallsArr.push(userMalls);
              }

              setSelectedMalls(userMallsArr);
            })
            .catch(() => {
              setSelectedMalls([]);
            });
        }
      } else {
        console.log(formData, 'new form data');
      }
    }
  }, [dialogOpen]);

  const handleCloseDialog = () => {
    setFormData({});
    setSelectPass();
    setSelectedBranches([]);
    setSelectedMalls([]);
    setSelectedRole([]);
    setDialogOpen(false);
    setCanCancel(false);
    setUser({});
  };

  return (
    <Dialog
      className="dialogs"
      open={dialogOpen}
      onClose={() => handleCloseDialog()}
    >
      <div className="page-width">
        <DialogTitle style={{ padding: '20px 24px' }}>
          {modalType === 'new'
            ? language.commons.add_user
            : language.commons.update_user_info}
        </DialogTitle>
        <DialogTitle
          style={{
            fontWeight: '400',
            color: 'gray',
            margin: '0',
            fontSize: '15px',
            padding: '0px 24px',
          }}
        >
          {modalType === 'new'
            ? language.components.AddEditUserModal.list_warning
            : language.components.AddEditUserModal.info_warning}
        </DialogTitle>
      </div>

      <hr />

      <DialogContent>
        <>
          {modalType === 'edit' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '5%',
              }}
            >
              <Typography>
                ({getUserTypeLabel(user?.type)}){' '}
                <strong style={{ color: 'blue' }}>
                  {user?.name} {user?.lastname}
                </strong>{' '}
                {language.components.AddEditUserModal.authority}:
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{language.commons.labels.user_status}:</div>
                  <div
                    style={{
                      color: userActive ? 'green' : 'red',
                      marginInline: '5px',
                    }}
                  >
                    {userActive ? 'Aktif' : 'Pasif'}
                  </div>
                  <Switch
                    id="userSwitch"
                    checked={userActive}
                    onChange={() => handleSetUserInactive(!userActive)}
                    name="status"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Typography>
              </div>
            </div>
          )}

          {modalType === 'new' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div>{language.commons.column.user_type}</div>
              <Grid>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={formData.type}
                  >
                    <FormControlLabel
                      value="highadmin"
                      control={<Radio />}
                      label={language.commons.roles.high_admin}
                      onClick={handleChangeUserType}
                    />
                    <FormControlLabel
                      value="admin"
                      control={<Radio />}
                      label={language.commons.roles.admin}
                      onClick={handleChangeUserType}
                    />
                    <FormControlLabel
                      value="corporational"
                      control={<Radio />}
                      label={language.commons.roles.store_user}
                      onClick={handleChangeUserType}
                    />
                    <FormControlLabel
                      value="user"
                      control={<Radio />}
                      label={language.commons.roles.user}
                      onClick={handleChangeUserType}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </div>
          )}

          <hr />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div>{language.commons.user_infos}</div>
            <Grid xs={12} spacing={2} container style={{ textAlign: 'right' }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    {language.commons.labels.username}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    label={language.commons.labels.username}
                    name="username"
                    placeholder={language.commons.labels.username}
                    onChange={handleChange}
                    value={formData.username}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="name"
                  label={language.commons.labels.name}
                  placeholder={language.commons.labels.name}
                  variant="outlined"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="surname"
                  label={language.commons.labels.lastname}
                  required
                  placeholder={language.commons.labels.lastname}
                  variant="outlined"
                  value={formData.surname}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {modalType === 'edit' ? (
                <CompleteButton
                  label={language.commons.labels.reset_password}
                  disabled={isResetPassButtonDisabled}
                  onClick={() => handleResetPassword()}
                  sx={{
                    mt: 2,
                    ml: 'auto',
                    float: 'left',
                    width: '210px  ',
                  }}
                />
              ) : (
                <>
                  <Grid item xs={6}>
                    <TextField
                      name="password"
                      label={language.commons.labels.password}
                      required
                      placeholder={language.commons.labels.password}
                      variant="outlined"
                      type="password"
                      value={
                        modalType === 'edit' ? user.password : formData.password
                      }
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="selectpass"
                      label={language.commons.labels.again_password}
                      required
                      placeholder={language.commons.labels.again_password}
                      variant="outlined"
                      type="password"
                      value={selectpass}
                      onChange={(e) => setSelectPass(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>

          <hr />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div>{language.commons.user_contact}</div>
            <Grid xs={12} spacing={2} container style={{ textAlign: 'right' }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="email"
                  label={language.commons.labels.e_mail}
                  placeholder={language.commons.labels.e_mail}
                  variant="outlined"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={() => {
                    if (formData.username === '') {
                      setFormData({
                        ...formData,
                        username: formData.email?.toLowerCase(),
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="phone"
                  type="number"
                  label={language.commons.labels.phone}
                  placeholder="5xxxxxxxxx"
                  variant="outlined"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                  onInput={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue < 0) {
                      inputValue = '0';
                    }
                    if (inputValue.startsWith('0')) {
                      inputValue = inputValue.slice(1);
                    }
                    if (inputValue.length > 10) {
                      inputValue = inputValue.slice(0, 10);
                    }
                    if (inputValue !== e.target.value) {
                      e.target.value = inputValue;
                      setFormData({
                        ...formData,
                        phone: inputValue,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <hr />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div>{language.commons.user_location}</div>
            <Grid xs={12} spacing={2} container style={{ textAlign: 'right' }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{language.commons.labels.mall}</InputLabel>
                  <Select
                    name="selectedMalls"
                    value={selectedMalls}
                    onChange={handleMallChange}
                    required
                    multiple
                    // onClose={() => getBranches()}
                    label={language.commons.labels.mall}
                  >
                    {malls
                      ?.filter(
                        (mall) =>
                          mall.offices &&
                          Array.isArray(mall.offices) &&
                          mall.offices.length >= 1
                      )
                      .map((mall) => (
                        <MenuItem key={mall.id} value={mall}>
                          {mall.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {formData.type === 'corporational' && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>{language.commons.column.store}</InputLabel>
                    <Select
                      name="selectedBranch"
                      value={selectedBranches}
                      multiple
                      required
                      onChange={handleChange}
                      label={language.commons.column.store}
                    >
                      {branches?.map((branch, index) => (
                        <MenuItem key={index} value={branch.branchID}>
                          {branch.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </div>

          {selectedMalls?.length > 0 && (
            <>
              <hr />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <div>{language.commons.labels.malls}</div>
                <Grid
                  xs={12}
                  spacing={2}
                  container
                  style={{ textAlign: 'right' }}
                >
                  <Grid
                    item
                    xs={8}
                    container
                    style={{ display: 'contents' }}
                    alignItems="center"
                    spacing={2}
                  >
                    {selectedMalls.map((selectedMall, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={
                            malls.find((mall) => mall?.id === selectedMall?.id)
                              ?.title || 'Unknown Mall'
                          }
                          variant="outlined"
                          sx={{ borderRadius: 0 }}
                          onDelete={() => handleRemoveMall(selectedMall)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {storeNone && selectedBranches?.length > 0 && (
            <>
              <hr />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <div>{language.commons.labels.stores}</div>
                <Grid
                  xs={12}
                  spacing={2}
                  container
                  style={{ textAlign: 'right' }}
                >
                  <Grid
                    item
                    xs={8}
                    container
                    style={{ display: 'contents' }}
                    alignItems="center"
                    spacing={2}
                  >
                    {selectedBranches.map((branchID, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={
                            branches.find(
                              (branch) => branch.branchID === branchID
                            )?.title || 'Unknown Branch'
                          }
                          variant="outlined"
                          sx={{ borderRadius: 0 }}
                          onDelete={() => handleRemoveBranch(branchID)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          <hr />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div>{language.commons.labels.user_auth}</div>
            {formData?.type !== 'user' ? (
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole?.includes('operations')}
                      onChange={() => handleRoleChange('operations')}
                      name="isOperator"
                    />
                  }
                  label={language.commons.labels.operation}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole?.includes('manager')}
                      onChange={() => handleRoleChange('manager')}
                      name="isAdmin"
                    />
                  }
                  label={language.commons.labels.manager}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole?.includes(
                        'technical-support-staff'
                      )}
                      onChange={() =>
                        handleRoleChange('technical-support-staff')
                      }
                      name="technicalSupportStaff"
                    />
                  }
                  label={language.commons.labels.technical_support}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole?.includes(
                        'technical-support-manager'
                      )}
                      onChange={() =>
                        handleRoleChange('technical-support-manager')
                      }
                      name="technicalSupportManager"
                    />
                  }
                  label={language.commons.labels.technical_manager}
                />
              </Grid>
            ) : (
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole?.includes('mall-management')}
                      onChange={() => handleRoleChange('mall-management')}
                      name="mallManagement"
                    />
                  }
                  label={language.commons.labels.mall_manager}
                />
              </Grid>
            )}
          </div>
          {formData?.type !== 'corporational' && formData?.type !== 'user' && (
            <>
              {' '}
              <hr />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  padding: '5px 24px',
                }}
              >
                <div style={{ textAlign: 'start', flexBasis: '25%' }}>
                  {language.commons.labels.cancel_auth}
                </div>
                <Switch
                  id="cancelSwitch"
                  checked={canCancel}
                  onChange={handleSwitchChange}
                  name="status"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </>
          )}
        </>
      </DialogContent>

      <DialogActions
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            onClick={() => {
              handleCloseDialog();
            }}
          >
            {language.commons.cancel}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleComplete();
            }}
          >
            {modalType === 'edit'
              ? language.commons.update
              : language.commons.add_user}
          </Button>
        </Stack>
      </DialogActions>

      <Dialog
        open={dialogPasswordOpen}
        onClose={handleDialogPasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {language.commons.update}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {language.components.AddEditUserModal.password_warning}
          </DialogContentText>
          <br />
          <DialogContentText
            disabled
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
            }}
            id="alert-dialog-description"
          >
            <TextField
              disabled
              fullWidth
              type="text"
              label={language.commons.labels.password}
              name="password"
              value={formData.password}
              // onChange={handleTextFieldChange}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleCopyPassword}>
                      <ContentCopyIcon color="action" />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogPasswordClose}>
            {language.commons.close}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default AddEditUserModal;
