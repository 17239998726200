import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
// @mui
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Typography,
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  Divider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../components/iconify';
// sections
import api from 'src/services/BaseService';
import { tokenService } from 'src/services';
import CreateWorkOrderModal from 'src/components/modals/CreateWorkOrderModal';
// ----------------------------------------------------------------------
import { StatusList } from 'src/types/statusTypes';
import DynamicTable from 'src/components/shared/DynamicTable';
import EditButton from 'src/components/shared/EditButton';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';

const calculateAndFormatDeliveryTime = (createdDateTime, completedDateTime) => {
  const createdMoment = moment.unix(createdDateTime);
  const completedMoment = moment.unix(completedDateTime);
  const duration = moment.duration(completedMoment.diff(createdMoment));
  const hours = duration.asHours();

  if (hours < 1) {
    const minutes = Math.round(hours * 60);
    return `${minutes} dakika`;
  } else {
    const roundedHours = Math.floor(hours);
    const remainingMinutes = Math.round((hours - roundedHours) * 60);
    if (remainingMinutes > 0) {
      return `${roundedHours} saat ${remainingMinutes} dakika`;
    } else {
      return `${roundedHours} saat`;
    }
  }
};

export default function FinishedJobsPage() {
  const { language } = useLocalization();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const TABLE_HEAD = [
    {
      id: 'createdDateTime',
      label: language.commons.labels.createdDateTime,
      alignRight: false,
      format: (value) => moment.unix(value).format('DD/MM/YYYY'),
    },
    {
      id: 'createdDateTimeFormat',
      label: language.commons.labels.createdDateTimeFormat,
      alignRight: false,
      format: (value, row) => moment.unix(row.createdDateTime).format('HH:mm'),
    },
    {
      id: 'barcode',
      label: language.commons.labels.barcode,
      alignRight: false,
      sx: { width: 95 },
      format: (value, row) =>
        row.apiUserType === 'handsfree' ? row.orderNumber : row.barcode,
    },
    {
      id: 'title',
      label: language.commons.labels.service_title,
      alignRight: false,
      format: (value, row) => {
        let serviceType =
          row.apiUserType === 'mall-logistics'
            ? 'Taşıma'
            : row.apiUserType === 'eren-holding'
            ? 'Barkodlu Taşıma'
            : row.apiUserType === 'getir'
            ? 'Getir'
            : row.apiUserType === 'handsfree'
            ? 'Handsfree'
            : row.apiUserType;

        if (
          row.dataJSON.isMultipleTransportation === true ||
          row.dataJSON.isMultipleTransportation === '1'
        ) {
          serviceType += ' (Ç)';
        }

        return serviceType;
      },
    },
    {
      id: 'from',
      label: language.commons.labels.from,
      alignRight: false,
      sortBy: 'from.title',
      format: (value, row) => row.from.title,
    },
    {
      id: 'to',
      label: language.commons.labels.to,
      alignRight: false,
      sortBy: 'to.title',
      format: (value, row) => row.to.title,
    },
    {
      id: 'completedDateTime',
      label: language.commons.labels.completedDateTime,
      alignRight: false,
      format: (value, row) =>
        row.completedDateTime ? moment.unix(value).format('DD/MM/YYYY') : '',
    },
    {
      id: 'completedDateTimeFormat',
      label: language.commons.labels.completedDateTimeFormat,
      alignRight: false,
      format: (value, row) =>
        row.completedDateTime
          ? moment.unix(row.completedDateTime).format('HH:mm')
          : '',
    },
    {
      id: 'processTime',
      label: language.commons.labels.processTime,
      alignRight: false,
      format: (value, row) =>
        row.completedDateTime
          ? calculateAndFormatDeliveryTime(
              row.createdDateTime,
              row.completedDateTime
            )
          : '',
    },
    {
      id: 'porterNameLastname',
      label: language.commons.labels.porterNameLastname,
      alignRight: false,
      sx: { maxWidth: 95 },
    },
    {
      id: 'orderInventory',
      label: language.commons.labels.orderInventory,
      alignRight: false,
      sx: { maxWidth: 95 },
    },
    {
      id: 'usedVehicles',
      label: language.commons.labels.usedVehicles,
      sx: { maxWidth: 95 },
      alignRight: false,
      format: (value, row) => {
        if (row?.usedVehicles?.length === 1) {
          return (
            <>
              <div className="customRow">
                <div style={{ textAlign: 'right' }}>
                  {row?.usedVehicles.map((e, index) => (
                    <>
                      <div style={{ textAlign: 'right' }}>
                        {e.transportationTool === 'Ring'
                          ? 'Koli/Paket (Ring)'
                          : e.transportationTool}
                        {row?.usedVehicles?.length > 1 ? '' : ''}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </>
          );
        } else if (row?.usedVehicles?.length > 1) {
          return (
            <>
              <Button
                onClick={() => setSelectedJob(row)}
                variant="contained"
                sx={{ color: 'white' }}
              >
                {language.commons.review}
              </Button>
            </>
          );
        } else {
          return (
            <>
              <div className="customRow">
                <div>
                  <div style={{ textAlign: 'right' }}>
                    {row?.requestedTransportationTool === 'Ring'
                      ? 'Koli/Paket (Ring)'
                      : row?.requestedTransportationTool}
                  </div>
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      id: 'status',
      label: language.commons.labels.status,
      sx: { width: 245 },
      alignCenter: true,
      format: (value, row) => {
        const statusLabel = StatusList.filter(
          (item) => item.id.toString() === row.status
        )[0]?.label;
        let backgroundColor = '';
        if (
          ['1', '10', '15', '16', '17', '21', '22', '28'].includes(row.status)
        ) {
          backgroundColor = 'rgba(10, 127, 50, 0.24)';
        } else if (
          ['3', '9', '11', '12', '13', '14', '20', '26'].includes(row.status)
        ) {
          backgroundColor = 'rgba(247, 169, 55, 0.36)';
        } else if (['2', '23', '24', '25', '29'].includes(row.status)) {
          backgroundColor = 'rgba(216, 27, 96, 0.4)';
        } else if (['4', '5', '6', '7', '8', '19', '27'].includes(row.status)) {
          backgroundColor = 'rgb(87, 90, 255, 0.4)';
        }
        return (
          <div
            style={{
              backgroundColor,
              padding: '4px 7px',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            {statusLabel}
          </div>
        );
      },
    },
    {
      id: 'operation',
      label: '',
      alignCenter: true,
      format: (value, row) => (
        <EditButton
          tooltip="Gönderi Detay"
          icon={'bx:detail'}
          onClick={() =>
            window.open(
              `/dashboard/finished-jobs-detail/${row.barcode}`,
              '_blank'
            )
          }
        />
      ),
      sx: { width: 245 },
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [showButton, setShowButton] = useState(true); // Butonun görünürlüğünü kontrol eder

  // const steps = [
  //   {
  //     target: '.my-zero-step',
  //     content: 'Eğitime Hoşgeldiniz ',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.my-first-step',
  //     content: 'Bu ilk Adımda İş emri oluşturabilirsiniz',
  //     disableBeacon: true,

  //     hideFooter: true,
  //   },
  //   {
  //     target: '.my-second-step',
  //     content:
  //       'Burada Barkod Numaranızı Girip Aradığınız Barkod numarasına sahip emri görebilirsiniz',
  //     styles: {
  //       options: {
  //         zIndex: 10000,
  //       },
  //     },
  //     title: 'The schedule',
  //   },
  //   {
  //     target: '.my-k-step',
  //     content: 'Burada Seçebilirsiniz ',
  //     disableBeacon: true,
  //     disableOverlayClose: true,
  //     hideCloseButton: true,
  //     hideFooter: true,
  //     spotlightClicks: true,
  //     styles: {
  //       options: {
  //         zIndex: 10000,
  //       },
  //     },
  //   },
  //   {
  //     target: '.my-third-step',
  //     content:
  //       'Burada aramak istediğiniz  İş Emirlerini arayıp filtreleyebilirsiniz ',
  //   },
  // ];
  // const handleJoyrideCallback = (data) => {
  //   const { action, index, status, type } = data;
  //   //console.log("datak",type);

  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     setRun(false);
  //     setStepIndex(0);
  //     setShowButton(true);
  //   } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
  //     const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
  //     if (index === 0) {
  //       setTimeout(() => {
  //         setRun(true);
  //         setStepIndex(nextStepIndex);
  //       }, 400);
  //     } else if (index === 1) {
  //       if (action === ACTIONS.PREV) {
  //         setOpenCreateModal(false);
  //       } else {
  //         setOpenCreateModal(true);
  //       }
  //       setRun(false);
  //       setStepIndex(nextStepIndex);
  //       setTimeout(() => {
  //         setRun(true);
  //       }, 400);
  //     } else if (index === 2) {
  //       setRun(false);
  //       setStepIndex(nextStepIndex);

  //       setTimeout(() => {
  //         setRun(true);
  //       }, 400);
  //     } else if (index === 3) {
  //       setRun(true);
  //       setStepIndex(nextStepIndex);
  //     } else {
  //       setOpenCreateModal(false);
  //       setStepIndex(nextStepIndex);
  //     }
  //   }
  // };
  const handleClickOpen = () => {
    setRun(stepIndex === 3 ? false : run);
    setOpenCreateModal(!openCreateModal);
    setStepIndex(stepIndex === 3 ? 4 : stepIndex);
  };
  const startTour = () => {
    setRun(true); // Turu başlatır
    setShowButton(false); // Butonu gizler
  };
  const customer = useSelector((state) => state.customerReducer);

  const location = useLocation();
  const showCreateModal = location.state?.showCreateModal;

  const [open, setOpen] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [barcode, setBarcode] = useState('');
  const [selectedCargoStatus, setSelectedCargoStatus] = useState([]);
  const [selectedShipmentType, setSelectedShipmentType] = useState('Tümü');
  const [locations, setLocations] = useState([]);
  const [storeLocationID, setStoreLocationID] = useState();
  const [branches, setBranches] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [originalOrderList, setOriginalOrderList] = useState([]);
  const [fromTitles, setFromTitles] = useState(['Tümü']);
  const [toTitles, setToTitles] = useState(['Tümü']);
  const [selectedFromTitle, setSelectedFromTitle] = useState('Tümü');
  const [selectedCount, setSelectedCount] = useState('');
  const [selectedPerson, setSelectedPerson] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('Tümü');
  const [selectedToTitle, setSelectedToTitle] = useState('Tümü');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [showPopUpError, setShowPopUpError] = useState(false);

  const [filterModal, setFilterModal] = useState(false);

  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };
  // Service test

  function getLocations(directions) {
    const acceptGoodsID = localStorage.getItem('easypointID');
    // console.log('acceptGoods: ', acceptGoodsID);

    const filteredDirections = directions?.filter(
      (dir) => !dir.handle.includes('Easypoint')
    );
    api
      .post('locations/get-locations', {
        branchID: customer?.customer?.id,
      })
      .then((response) => {
        if (response.data.status) {
          //console.log('lokasyonlar: ', response.data);
          setStoreLocationID(response?.data?.result[0]?.locationID);

          const permutations = [];

          for (let i = 0; i < response.data.result?.length; i++) {
            permutations.push({
              title: `Mal Kabulden → ${response.data.result[i]?.title}`,
              handle: 'MallLogisticsToStore',
              type: 'new',
              from: acceptGoodsID,
              to: response.data.result[i]?.locationID,
            });
            permutations.push({
              title: `${response.data.result[i].title} → Mal Kabule`,
              handle: 'StoreToMallLogistics',
              type: 'new',
              from: response.data.result[i].locationID,
              to: acceptGoodsID,
            });

            for (let j = 0; j < response.data.result?.length; j++) {
              if (i !== j) {
                permutations.push({
                  title: `${response.data.result[i].title} → ${response.data.result[j].title}`,
                  handle: 'StoreToStore',
                  type: 'new',
                  from: response.data.result[i].locationID,
                  to: response.data.result[j].locationID,
                });
              }
            }
          }

          directions = filteredDirections.concat(permutations);
          setLocations(directions);
          if (showCreateModal && showCreateModal === true) {
            setOpenCreateModal(true);
          }
        } else {
          // toast.error(response.data.message);
          // console.log('lokasyonlar false döndü');
        }
      })
      .catch((error) => {
        // toast.error(error.message);
        // console.log("lokasyonlar catch'e girdi", error);
      });
  }

  function getDirections() {
    api
      .get('directions/get')
      .then((response) => {
        if (response.data.status) {
          if (tokenService?.getUser()?.accountCategory === 'store') {
            getLocations(
              response.data.result.filter((x) => {
                x.handle != 'StoreToStore' ||
                  x.title === "Mağazadan Mall Logistics'e" ||
                  x.title === "Mall Logistics'ten Mağazaya" ||
                  x.title === "Easypoint'ten Mall Logistics'e" ||
                  x.title === "Mall Logistics'ten Easypoint'e";
              })
            );
          } else {
            if (showCreateModal && showCreateModal === true) {
              setOpenCreateModal(true);
            }
          }
        } else {
          // console.log('yönler false döndü');
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log("yönler catch'e girdi", error);
        // toast.error(error.message);
      });
  }

  function getFilteredOrderList() {
    setOrderList([]);
    if (
      (customer?.customer?.id &&
        tokenService?.getUser()?.accountCategory === 'store') ||
      (tokenService?.getUser()?.accountCategory === 'company' &&
        customer?.customer?.branchID)
    ) {
      api
        .post('posts/get', {
          branchID:
            tokenService?.getUser()?.accountCategory === 'company'
              ? customer?.customer?.branchID
              : customer?.customer?.id,
          status:
            selectedCargoStatus && selectedCargoStatus.length > 0
              ? selectedCargoStatus.map((x) => x.id)
              : StatusList.filter((x) => x.id != 0).map((x) => x.id),
          startDate: startDate
            ? moment(startDate).format('YYYY-MM-DD')
            : startDate,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : endDate,
          limit: 5000,
          orderBy: 'created',
          useForce:
            tokenService?.getUser()?.accountCategory === 'company' &&
            customer?.customer?.branchID
              ? true
              : false,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            let filteredResults = response.data.result;

            if (selectedShipmentType !== 'Tümü') {
              filteredResults = filteredResults.filter(
                (order) => order.apiUserType === selectedShipmentType
              );
            }

            if (selectedFromTitle !== 'Tümü') {
              filteredResults = filteredResults.filter(
                (order) => order.from.title === selectedFromTitle
              );
            }

            if (selectedToTitle !== 'Tümü') {
              filteredResults = filteredResults.filter(
                (order) => order.to.title === selectedToTitle
              );
            }

            if (selectedCount !== '') {
              filteredResults = filteredResults.filter(
                (order) => order.orderInventory === selectedCount
              );
            }

            if (selectedPerson !== '') {
              const lowerCaseSelectedPerson = selectedPerson.toLowerCase();
              filteredResults = filteredResults.filter((order) =>
                order?.porterNameLastname
                  ?.toLowerCase()
                  ?.includes(lowerCaseSelectedPerson)
              );
            }

            if (selectedVehicle !== 'Tümü') {
              const lowerCaseSelectedVehicle = selectedVehicle.toLowerCase();
              filteredResults = filteredResults.filter((order) => {
                if (order?.usedVehicles?.length > 0) {
                  // Eğer usedVehicles dizisi varsa, bazı araçlarda aranan değeri içerip içermediğini kontrol et
                  return order?.usedVehicles.some((e) =>
                    e?.transportationTool
                      ?.toLowerCase()
                      .includes(lowerCaseSelectedVehicle)
                  );
                } else {
                  // usedVehicles dizisi yoksa veya boşsa, requestedTransportationTool'u kontrol et
                  return order?.requestedTransportationTool
                    ?.toLowerCase()
                    .includes(lowerCaseSelectedVehicle);
                }
              });
            }

            setOrderList(filteredResults);
            setOriginalOrderList(response.data.result);
            // Extract unique from.title and to.title values
            const uniqueFromTitles = [
              'Tümü',
              ...new Set(
                response.data.result
                  .map((order) => order.from.title)
                  .filter((title) => title !== null && title !== undefined)
              ),
            ];
            const uniqueToTitles = [
              'Tümü',
              ...new Set(
                response.data.result
                  .map((order) => order.to.title)
                  .filter((title) => title !== null && title !== undefined)
              ),
            ];

            setFromTitles(uniqueFromTitles);
            setToTitles(uniqueToTitles);
          } else {
            setOrderList([]);
            setOriginalOrderList([]);
            toast.error('İş emri bulunamadı.');
          }
        })
        .catch((error) => {
          setOrderList([]);
          setOriginalOrderList([]);
          toast.error('İş emri bulunamadı.');
        });
    } else if (
      (tokenService?.getUser()?.accountCategory === 'mall-logistics' ||
        tokenService?.getUser()?.accountCategory === 'mall') &&
      customer?.customer?.id !== undefined
    ) {
      api
        .post('posts/get', {
          mallID: customer?.customer?.id,
          status:
            selectedCargoStatus && selectedCargoStatus.length > 0
              ? selectedCargoStatus.map((x) => x.id)
              : StatusList.filter((x) => x.id != 0).map((x) => x.id),
          startDate: startDate
            ? moment(startDate).format('DD-MM-YYYY')
            : startDate,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate,
          limit: 5000,
          orderBy: 'created',
        })
        .then((response) => {
          if (response.data.status) {
            let filteredResults = response.data.result;

            if (selectedShipmentType !== 'Tümü') {
              filteredResults = filteredResults.filter(
                (order) => order.apiUserType === selectedShipmentType
              );
            }

            if (selectedFromTitle !== 'Tümü') {
              filteredResults = filteredResults.filter(
                (order) => order.from.title === selectedFromTitle
              );
            }

            if (selectedToTitle !== 'Tümü') {
              filteredResults = filteredResults.filter(
                (order) => order.to.title === selectedToTitle
              );
            }

            if (selectedCount !== '') {
              filteredResults = filteredResults.filter(
                (order) => order.orderInventory === selectedCount
              );
            }

            if (selectedPerson !== '') {
              const lowerCaseSelectedPerson = selectedPerson.toLowerCase();
              filteredResults = filteredResults.filter((order) =>
                order?.porterNameLastname
                  ?.toLowerCase()
                  ?.includes(lowerCaseSelectedPerson)
              );
            }

            if (selectedVehicle !== 'Tümü') {
              const lowerCaseSelectedVehicle = selectedVehicle.toLowerCase();
              filteredResults = filteredResults.filter((order) => {
                if (order?.usedVehicles?.length > 0) {
                  // Eğer usedVehicles dizisi varsa, bazı araçlarda aranan değeri içerip içermediğini kontrol et
                  return order?.usedVehicles.some((e) =>
                    e?.transportationTool
                      ?.toLowerCase()
                      .includes(lowerCaseSelectedVehicle)
                  );
                } else {
                  // usedVehicles dizisi yoksa veya boşsa, requestedTransportationTool'u kontrol et
                  return order?.requestedTransportationTool
                    ?.toLowerCase()
                    .includes(lowerCaseSelectedVehicle);
                }
              });
            }

            setOrderList(filteredResults);
            setOriginalOrderList(response.data.result);

            const uniqueFromTitles = [
              'Tümü',
              ...new Set(
                response.data.result
                  .map((order) => order.from.title)
                  .filter((title) => title !== null && title !== undefined)
              ),
            ];
            const uniqueToTitles = [
              'Tümü',
              ...new Set(
                response.data.result
                  .map((order) => order.to.title)
                  .filter((title) => title !== null && title !== undefined)
              ),
            ];

            setFromTitles(uniqueFromTitles);
            setToTitles(uniqueToTitles);
          } else {
            setOrderList([]);
            setOriginalOrderList([]);
            toast.error('İş emri bulunamadı.');
          }
        })
        .catch((error) => {
          setOrderList([]);
          setOriginalOrderList([]);
          toast.error('İş emri bulunamadı.');
        });
    }
  }

  function exportToExcel() {
    let temp1 = orderList.map((x, index) => {
      let usedVehiclesText = '';

      if (x?.usedVehicles && x?.usedVehicles.length > 0) {
        usedVehiclesText = x.usedVehicles
          .map(
            (vehicle) =>
              `${
                vehicle.transportationTool === 'Ring'
                  ? 'Koli/Paket (Ring)'
                  : vehicle.transportationTool
              } (${vehicle.transportationToolCount})`
          )
          .join(', ');
      } else if (x?.requestedTransportationTool) {
        usedVehiclesText = `${
          x.requestedTransportationTool === 'Ring'
            ? 'Koli/Paket (Ring)'
            : x.requestedTransportationTool
        } (1)`;
      }

      let a = {
        id: index + 1,
        createdDateTime: moment(x.createdDateTime * 1000).format('DD/MM/YYYY'),
        createdTime: moment(x.createdDateTime * 1000).format('HH:mm'),
        barcode: x.barcode,
        title: x.title.split(' - ')[0] + ' - ' + x.title.split(' - ')[1],
        from: x.from.title,
        to: x.to.title ? x.to.title : '',
        orderInventory: x?.orderInventory,
        usedVehicles: usedVehiclesText,
        completedDateTime: x.completedDateTime
          ? moment(new Date(Number(x.completedDateTime * 1000))).format(
              'DD/MM/YYYY'
            )
          : '',
        completedTime: x.completedDateTime
          ? moment(new Date(Number(x.completedDateTime * 1000))).format('HH:mm')
          : '',
        porterNameLastname: x?.porterNameLastname ? x?.porterNameLastname : '',
        status: StatusList.filter((item) => item.id.toString() === x.status)[0]
          ?.label,
      };
      return a;
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          'Oluşturulma Tarihi': 'string',
          'Oluşturulma Saati': 'string',
          'Kargo Takip No': 'string',
          'Firma/Kişi': 'string',
          'Çıkış Noktası': 'string',
          'Varış Noktası': 'string',
          'Adet Bilgisi': 'string',
          'Taşıma Bilgisi': 'string',
          'Tamamlanma Tarihi': 'string',
          'Tamamlanma Saati': 'string',
          'Taşıyan Kişi': 'string',
          'İşlem Durumu': 'string',
        },
        sheetTitle: 'İşler',
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }

  function getBranches() {
    // console.log('customer mallID: ', customer?.customer?.id);
    api
      .post('flow/get-malls-branches', {
        mallID: customer?.customer?.id,
      })
      .then((res) => {
        if (res.data.status) {
          setBranches(
            res.data.result.map((branch) => ({
              branchID: branch.branchID,
              title: branch.title,
              locations: branch.locations,
            }))
          );
        }
      })
      .catch((err) => {
        // console.log("get branches catch'e girdi: ", err);
        setBranches([]);
      });
  }

  useEffect(() => {
    if (customer) {
      // console.log('customer: ', customer);
      getFilteredOrderList();
      getDirections();
      if (tokenService?.getUser()?.accountCategory === 'mall-logistics') {
        getBranches();
      }
    }
  }, [customer]);

  const handleSearchByBarcode = (barcode) => {
    setOrderList([]);
    if (!barcode) {
      setOrderList(originalOrderList);
    } else {
      const filteredOrders = originalOrderList.filter((order) =>
        order.barcode.includes(barcode)
      );
      setOrderList(filteredOrders);
    }
  };

  function clearFilters() {
    setStartDate(null);
    setEndDate(null);
    setSelectedCargoStatus([]);
    setSelectedShipmentType('Tümü');
    setSelectedFromTitle('Tümü');
    setSelectedToTitle('Tümü');
    getFilteredOrderList();
    setBarcode('');
    setSelectedCount('');
    setSelectedPerson('');
    setSelectedVehicle('Tümü');
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCargoStatus(value);
  };

  const handleShipmentTypeChange = (event) => {
    setSelectedShipmentType(event.target.value);
  };

  const asiMetFunct = () => {
    setOpenCreateModal(true);
  };
  const cargoList = [
    'Tümü',
    'Koli/Paket (Ring)',
    'Küçük Araç',
    'Büyük Araç',
    'Askılı Araç',
    'Orta Araç',
    'Palet',
  ];

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (filterModal && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [filterModal]);

  return (
    <>
      <Helmet>
        <title> {language.finished_jobs.titles.site_title} </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }} className="my-zero-step">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack direction="row">
            <Typography variant="h4" gutterBottom margin={0}>
              {language.finished_jobs.titles.work_orders}
            </Typography>
          </Stack>

          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            alignItems="end"
          >
            {tokenService.getUser()?.accountCategory !== 'mall' &&
              tokenService.getUser()?.frontAuth.includes('operations') && (
                <Button
                  className="my-first-step"
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => asiMetFunct()}
                >
                  {language.finished_jobs.buttons.create_workOrder}
                </Button>
              )}
            <ExportToExcelButton
              setShowPopUpError={setShowPopUpError}
              showPopUpError={showPopUpError}
              sx={{ marginLeft: '7px' }}
              onClick={() => exportToExcel()}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent={'end'}></Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>

        <Card>
          <Dialog
            open={selectedJob}
            onClose={() => setSelectedJob(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {language.finished_jobs.buttons.multiple_tools_type}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedJob?.usedVehicles.map((e, index) => (
                  <>
                    <div>
                      {e.transportationToolCount} sefer{' '}
                      {e.transportationTool === 'Ring'
                        ? 'Koli/Paket (Ring)'
                        : e.transportationTool}
                    </div>
                  </>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedJob(null)}>
                {language.commons.close}
              </Button>
            </DialogActions>
          </Dialog>

          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                paddingY={2}
                paddingX={1}
                spacing={2}
              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedCargoStatus}
                    input={<OutlinedInput ref={firstInputRef} autoFocus />}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em> {language.commons.cargo_status}</em>;
                      }
                      return selected.map((x) => x.label).join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {StatusList.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    ).map((x) => (
                      <MenuItem key={x.id} value={x}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    sx={{
                      flex: 1.5,
                      '& .MuiInputLabel-root': {
                        color: '#212B36',
                      },
                    }}
                    label={language.commons.person_carrying}
                    variant="outlined"
                    value={selectedPerson}
                    onChange={(e) => setSelectedPerson(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    sx={{
                      flex: 1.5,
                      '& .MuiInputLabel-root': {
                        color: '#212B36',
                      },
                    }}
                    label={language.commons.quantity_info}
                    variant="outlined"
                    value={selectedCount}
                    onChange={(e) => setSelectedCount(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <Select
                    value={selectedVehicle}
                    input={<OutlinedInput />}
                    onChange={(e) => setSelectedVehicle(e.target.value)}
                    renderValue={(selected) => {
                      if (selected === 'Tümü') {
                        return <em>{language.commons.vehicle_type}</em>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {cargoList
                      .sort((a, b) => a.localeCompare(b))
                      .map((x, index) => (
                        <MenuItem
                          key={index}
                          value={x === 'Koli/Paket (Ring)' ? 'Ring' : x}
                        >
                          {x}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <Select
                    displayEmpty
                    value={selectedShipmentType}
                    input={<OutlinedInput />}
                    onChange={handleShipmentTypeChange}
                    renderValue={(selected) => {
                      if (selected === 'Tümü') {
                        return <em>{language.commons.shipment_type}</em>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="Tümü">
                      <em>Tümü</em>
                    </MenuItem>
                    <MenuItem value="getir">
                      {language.commons.brands.getir}
                    </MenuItem>
                    <MenuItem value="handsfree">
                      {language.commons.brands.handsfree}
                    </MenuItem>
                    <MenuItem value="hepsiburada">
                      {language.commons.brands.hepsiburada}
                    </MenuItem>
                    <MenuItem value="mall-logistics">
                      {language.commons.brands.mallLogistics}
                    </MenuItem>
                    <MenuItem value="trendyol">
                      {language.commons.brands.trendyol}
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <Select
                    displayEmpty
                    value={selectedFromTitle}
                    input={<OutlinedInput />}
                    onChange={(event) =>
                      setSelectedFromTitle(event.target.value)
                    }
                    renderValue={(selected) => {
                      if (selected === 'Tümü') {
                        return <em>{language.commons.exit_point}</em>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {fromTitles
                      .sort((a, b) => a.localeCompare(b))
                      .map((title) => (
                        <MenuItem key={title} value={title}>
                          {title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <Select
                    displayEmpty
                    value={selectedToTitle}
                    input={<OutlinedInput />}
                    onChange={(event) => setSelectedToTitle(event.target.value)}
                    renderValue={(selected) => {
                      if (selected === 'Tümü') {
                        return <em>{language.commons.destination_point}</em>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {toTitles
                      .sort((a, b) => a.localeCompare(b))
                      .map((title) => (
                        <MenuItem key={title} value={title}>
                          {title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack
                direction="column"
                alignItems="baseline"
                justifyContent="space-between"
                paddingY={2}
                paddingX={1}
                spacing={2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ flex: 1 }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ flex: 1 }}
                      format="DD/MM/YYYY"
                      label={language.commons.start_date}
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ flex: 1 }}
                      format="DD/MM/YYYY"
                      label={language.commons.end_date}
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
                </Stack>

                <div style={{ flex: 0.2 }}></div>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ flex: 1 }}
                  width={'100%'}
                >
                  <TextField
                    sx={{ flex: 1.5 }}
                    label="Barkod"
                    variant="outlined"
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                  />
                  <Button
                    sx={{
                      backgroundColor: '#2C7EF8',
                      fontWeight: '500',
                      flexShrink: 1,
                    }}
                    variant="contained"
                    onClick={() => handleSearchByBarcode(barcode)}
                  >
                    {language.commons.search}
                  </Button>
                </Stack>
              </Stack>
              <Divider sx={{ width: '100%' }} />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                paddingY={2}
                paddingX={1}
              >
                <ClearButton onClick={() => clearFilters()} />
                <FilterButton onClick={() => getFilteredOrderList()} />
              </Stack>
            </>
          </CustomFilter>

          <DynamicTable
            data={orderList}
            headers={TABLE_HEAD}
            initialOrder={'createdDateTime'}
            initalOrderDirection={'desc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
            rowOnClick={(row) =>
              window.open(
                `/dashboard/finished-jobs-detail/${row.barcode}`,
                '_blank'
              )
            }
          />
        </Card>
      </Stack>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {language.commons.edit}
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {language.commons.delete}
        </MenuItem>
      </Popover>

      {openCreateModal && (
        <CreateWorkOrderModal
          handleClickOpen={handleClickOpen}
          openCreateModal={openCreateModal}
          onCreateModal={() => setOpenCreateModal(false)}
          customer={customer}
          locations={locations}
          storeLocationID={storeLocationID}
          branches={branches}
        />
      )}
    </>
  );
}
