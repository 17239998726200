import React, { useState } from 'react';
import {
    Stack,
    Typography,
    TextField,
    Backdrop,
    CircularProgress,
    useMediaQuery,
    Card,
    Select,
    MenuItem,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DynamicTable from 'src/components/shared/DynamicTable';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import CustomFilter from 'src/components/filter/CustomFilter';

export default function ReceiptOperations() {
    const isMobile = useMediaQuery('(max-width:768px)');
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [barcode, setBarcode] = useState('');
    const [personName, setPersonName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [cargoName, setCargoName] = useState('');
    const [companyID, setCompanyID] = useState('');
    const [logs, setLogs] = useState([]);
    const [filterModal, setFilterModal] = useState(false);

    const TABLE_HEAD = [
        { id: 'entryDate', label: "Giriş Tarihi", alignRight: false },
        { id: 'personName', label: "Kişi Adı", alignRight: false },
        { id: 'companyName', label: "Firma Adı", alignRight: false },
        { id: 'barcodeNumber', label: "Barkod No", alignRight: false },
        { id: 'cargoCompany', label: "Kargo Firması", alignRight: false },
        { id: 'companyID', label: "Firma ID", alignRight: false },
    ];

    const convertTimestampToDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleSubmit = () => {
        console.log("Submit clicked");
    };

    const handleClear = () => {
        console.log("Clear clicked");
    };


    const handleModal = () => {
        setFilterModal(true);
    };

    const handleCloseModal = () => {
        setFilterModal(false);
    };


    return (
        <>
            <Helmet>
                <title>Faturalar | Mall Logistics Panel</title>
            </Helmet>
            <Stack style={{ paddingInline: '25px' }}>
                <Backdrop sx={{ color: '#fff', zIndex: 2, height: '100%' }} open={isBackdropOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Faturalar
                    </Typography>
                </Stack>

                {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}


                <Card>
                    <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={() => setFilterModal(false)}>
                        <Stack direction="column" alignItems="center" justifyContent="space-between">
                            <Stack width={'100%'} direction="column" alignItems="center" justifyContent="space-between" spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        sx={{ flex: 1, minWidth: '100%' }}
                                        label="Başlangıç Tarihi"
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                        maxDate={endDate}
                                        format="DD/MM/YYYY"
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        sx={{ flex: 1, minWidth: '100%' }}
                                        label="Bitiş Tarihi"
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        minDate={startDate}
                                        format="DD/MM/YYYY"
                                    />
                                </LocalizationProvider>

                                <TextField sx={{ flex: 1, minWidth: '100%' }} label="Barkod" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                                <TextField sx={{ flex: 1, minWidth: '100%' }} label="Kişi Adı" value={personName} onChange={(e) => setPersonName(e.target.value)} />
                                <Select
                                    sx={{ flex: 1, minWidth: '100%' }}
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value=""><em>Firma Seç</em></MenuItem>
                                    <MenuItem value="Firma A">Firma A</MenuItem>
                                    <MenuItem value="Firma B">Firma B</MenuItem>
                                </Select>
                                <Select
                                    sx={{ flex: 1, minWidth: '100%' }}
                                    value={cargoName}
                                    onChange={(e) => setCargoName(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value=""><em>Kargo Seç</em></MenuItem>
                                    <MenuItem value="Kargo X">Kargo X</MenuItem>
                                    <MenuItem value="Kargo Y">Kargo Y</MenuItem>
                                </Select>
                                <TextField sx={{ flex: 1, minWidth: '100%' }} label="Firma ID" value={companyID} onChange={(e) => setCompanyID(e.target.value)} />
                                <FilterButton onClick={handleSubmit} label={"ARA"} />
                                <ClearButton onClick={handleClear} />
                            </Stack>
                        </Stack>
                    </CustomFilter>
                    <DynamicTable
                        data={logs}
                        headers={TABLE_HEAD}
                        initialOrder={'createdDateTime'}
                        initalOrderDirection={'desc'}
                        isOrdered={true}
                        isMobile={isMobile}
                        handleModal={handleModal}
                        handleCloseModal={handleCloseModal}
                    />
                </Card>


            </Stack>
        </>
    )
}
