// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify/Iconify';
import { tokenService } from 'src/services';

// ----------------------------------------------------------------------

const icon = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

// const isUserAllowedForSafeBox = tokenService?.getUser()?.authLocations?.mall.some(x => x.id === "61973122632916808660");
// console.log(isUserAllowedForSafeBox);
const navConfig = [
  {
    title: 'İstatistikler',
    path: '/dashboard/app',
    icon: icon('wpf:statistics'),
    permissions: [
      'mall:manager:mall-administration',
      'mall-logistics:accounting:accountant',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:operations:admin',
      'mall-logistics:manager:superadmin',
      'store:manager:corporational',
      'store:manageroperations:corporational',
      'store:operations:corporational',
      'mall:mall-administration:admin',
    ],
    category: "Yönetim"
  },
  {
    title: 'İşlemler',
    path: '/dashboard/finished-jobs',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall:manager:mall-administration',
      'mall-logistics:accounting:accountant:',
      'mall-logistics:operations:highadmin',
      'mall-logistics:operations:porter',
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:operations:admin',
      'mall-logistics:manager:superadmin',
      'store:manager:corporational',
      'store:manageroperations:corporational',
      'store:operations:corporational',
      'mall:mall-administration:admin',
      'company:company-manager:user',
    ],
    category: "Yönetim"
  },
  // {
  //   title: 'Emanet Dolap Raporu',
  //   path: '/dashboard/safe-box-report',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: [
  //     'mall-logistics:manager:highadmin',
  //     'mall-logistics:manager:superadmin',
  //     'mall-logistics:operations:highadmin',
  //     'mall-logistics:operations:superadmin',
  //     'mall-logistics:mall-management:user'
  //   ],
  // },
  {
    title: 'Dolap İşlemleri',
    path: '/dashboard/safe-box-operations',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:superadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:operations:superadmin',
      'mall-logistics:mall-management:user',
    ],
    category: "Emanet İşlemleri",
    accordion: "Emanet İşlemleri"
  },
  {
    title: 'Vestiyer İşlemleri',
    path: '/dashboard/checkroom',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:superadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:operations:superadmin',
      'mall-logistics:mall-management:user',
    ],
    category: "Emanet İşlemleri",
    accordion: "Emanet İşlemleri"
  },

  // {
  //   title: 'Faturalar',
  //   path: '/dashboard/finished-jobs',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: [
  //     'mall-logistics:accounting',
  //     'mall-logistics:manager',
  //     'store:manager',
  //     'store:manageroperations',
  //   ],
  // },
  // {
  //   title: 'Fiyat Listeleri',
  //   path: '/dashboard/finished-jobs',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['mall-logistics:accounting', 'mall-logistics:manager'],
  // },
  {
    title: 'Porter Listesi',
    path: '/dashboard/porters',
    icon: icon('solar:bill-list-outline'),
    permissions: [
      'mall-logistics:hr:human-resource',
      'mall-logistics:accounting:human-resource',
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:superadmin',
    ],
    category: "Listeler"
  },
  // {
  //   title: 'AVM-Porter Eşleştirme',
  //   path: '/dashboard/finished-jobs',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['mall-logistics:hraccounting', 'mall-logistics:manager','mall-logistics:manager:superadmin'],
  // },
  // {
  //   title: 'Depo Oluşturma',
  //   path: '/dashboard/finished-jobs',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['store:manageroperations'],
  // },
  // {
  //   title: 'Kullanıcı Oluşturma',
  //   path: '/dashboard/users',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['store:manageroperations'],
  // },
  // {
  //   title: 'Vestiyer',
  //   path: '/dashboard/finished-jobs',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['mall:manager'],
  // },
  // {
  //   title: 'Emanet',
  //   path: '/dashboard/finished-jobs',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['mall:manager'],
  // },
  {
    title: 'Muhaberat Giriş',
    path: '/dashboard/muhaberat-entry',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manageroperations:highadmin',
      'mall-logistics:operations:muhaberat',
      'mall-logistics:manager:superadmin',
    ],
    category: 'Muhaberat',
  },
  {
    title: 'Muhaberat Çıkış',
    path: '/dashboard/muhaberat-output',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manageroperations:highadmin',
      'mall-logistics:operations:muhaberat',
      'mall-logistics:manager:superadmin',
      // 'mall-logistics:mall-management:user'
      'mall-logistics:exceptional:user',
    ],
    activeList: [
      '/dashboard/muhaberat-output',
      '/dashboard/muhaberat-output-users',
    ],
    category: 'Muhaberat',
  },
  {
    title: 'Mal Kabul Dosyası Giriş',
    path: '/dashboard/manuel-handshake',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manageroperations:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:manager:superadmin',
    ],
    category: "Mal Kabul"
  },
  {
    title: 'Mal Kabul Dosyası Listesi',
    path: '/dashboard/manuel-handshake-list',
    icon: icon('solar:bill-list-outline'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manageroperations:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:manager:superadmin',
      'company:company-manager:user',
    ],
    category: "Mal Kabul"
  },
  {
    title: 'Onay Bekleyen İrsaliyeler',
    path: '/dashboard/pre-handshake-list',
    icon: icon('solar:bill-list-outline'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:manager:superadmin',
    ],
    category: "Listeler"
  },
  {
    title:
      tokenService?.getUser()?.accountCategory === 'store'
        ? tokenService?.getUser()?.authLocations?.branch[0]?.mallID ===
          '98569271082166328155'
          ? 'Bags-Free'
          : 'Hands Free'
        : 'Hands Free',
    path: '/dashboard/handsfree',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'store:manager:corporational',
      'store:manageroperations:corporational',
      'store:operations:corporational',
    ],
  },
  {
    title: 'Tüm İşlemler',
    path: '/dashboard/all-actions',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall:manager:mall-administration',
      'mall-logistics:manager:superadmin',
    ],
  },
  {
    title: 'Robot Listeleri',
    path: '/dashboard/robot-lists',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:robot:highadmin',
      'mall-logistics:robot:porter',
      'mall-logistics:manager:superadmin',
    ],
    category: 'Robot',
  },
  {
    title: 'Robot İşlemleri',
    path: '/dashboard/robot-actions',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'mall-logistics:robot:highadmin',
      'mall-logistics:robot:porter',
      'mall-logistics:manager:superadmin',
    ],
    category: 'Robot',
  },
  // {
  //   title: 'Emanet Dolap Raporu',
  //   path: '/dashboard/monthly-prices',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['mall-logistics:manager:highadmin','mall-logistics:manager:superadmin'],
  // },
  // {
  //   title: 'Emanet Dolap Raporu (Yeni)',
  //   path: '/dashboard/monthly-cabine',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: ['mall-logistics:manager:highadmin','mall-logistics:manager:superadmin'],
  // },
  // {
  //   title: 'Teknik Destek Talepleri',
  //   path: '/dashboard/technical-support-requests',
  //   icon: icon('icon-park-outline:sales-report'),
  //   //permissions need to be set
  //   permissions: ['mall-logistics:accounting', 'mall-logistics:manager','mall-logistics:manager:superadmin'],
  // },
  // {
  //   title: 'Çalışan Oluşturma Onayı',
  //   path: '/dashboard/create-employee-approval',
  //   icon: icon('icon-park-outline:sales-report'),
  //   //permissions need to be set
  //   permissions: [
  //     'mall-logistics:robot',
  //     'mall-logistics:accounting',
  //     'mall-logistics:manager',
  //     'mall-logistics:operations',
  // 'mall-logistics:manager:superadmin',
  //   ],
  // },
  // {
  //   title: 'Müşteri Kayıt Etme Formu',
  //   path: '/dashboard/new-page',
  //   icon: icon('icon-park-outline:sales-report'),
  //   //permissions need to be set
  //   permissions: [
  //     'mall-logistics:robot',
  //     'mall-logistics:accounting',
  //     'mall-logistics:manager',
  //     'mall-logistics:operations',
  // 'mall-logistics:manager:superadmin',
  //   ],
  // },
  {
    title: 'AVM Mağaza Aktarımı',
    path: '/dashboard/fetch-branches',
    icon: icon('tabler:transfer'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:superadmin',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'AVM Listesi',
    path: '/dashboard/avm-list',
    icon: icon('solar:bill-list-outline'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manageroperations:highadmin',
      'mall:manager:mall-administration',
      'mall:operations:mall-administration',
      'mall:manageroperations:mall-administration',
      'mall-logistics:manager:superadmin',
    ],
    category: "Listeler"
  },
  {
    title: 'Mağaza Listesi',
    path: '/dashboard/store-list',
    icon: icon('solar:bill-list-outline'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manageroperations:highadmin',
      'mall:manager:mall-administration',
      'mall:operations:mall-administration',
      'mall:manageroperations:mall-administration',
      'mall-logistics:manager:superadmin',
    ],
    category: "Listeler"
  },
  // {
  //   title: 'Avm Listesi',
  //   path: '/dashboard/avm-list',
  //   icon: icon('icon-park-outline:sales-report'),
  //   permissions: [
  //     'mall-logistics:manager',
  //     'mall-logistics:operations',
  //     'mall-logistics:manageroperations',
  // 'mall-logistics:manager:superadmin:',
  //     'mall:manager',
  //     'mall:operations',
  //     'mall:manageroperations',
  //   ],
  // },
  {
    title: 'Kullanıcılar',
    path: '/dashboard/users',
    icon: icon('ph:users-three-light'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:accounting:accountant',
      'mall-logistics:manager:superadmin',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'Noktalar',
    path: '/dashboard/points',
    icon: icon('ph:map-pin-bold'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:accounting:accountant',
      'mall-logistics:manager:superadmin',
      'mall-logistics:operations:porter',
      'mall-logistics:manager:porter',
    ],
    category: "Listeler"
  },
  {
    title: 'QR İşlemleri',
    path: '/dashboard/qr',
    icon: icon('icomoon-free:qrcode'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manager:superadmin',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'Mağaza QR Kodu',
    path: '/dashboard/store-qr',
    icon: icon('icon-park-outline:sales-report'),
    permissions: [
      'store:manager:corporational',
      'store:operations:corporational',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'Rapor Oluştur',
    path: '/dashboard/create-report',
    icon: icon('carbon:report-data'),
    permissions: [
      'mall:manager:mall-administration',
      'mall-logistics:manager:superadmin',
      'mall-logistics:accounting:accountant:',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:admin',
      // 'mall-logistics:operations:porter',
      // 'mall-logistics:manager:porter',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'Kampanyalar',
    path: '/dashboard/campaign',
    icon: icon('mdi:hand-coin-outline'),
    permissions: [
      'mall-logistics:manager:highadmin',
      'mall-logistics:operations:highadmin',
      'mall-logistics:manager:superadmin',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'Teknik Destek Talepleri',
    path: '/dashboard/support-tickets',
    icon: icon('hugeicons:customer-support'),
    permissions: [
      'mall:manager:mall-administration',
      'mall-logistics:manager:superadmin',
      'mall-logistics:accounting:accountant:',
      'mall-logistics:operations:highadmin',
      'mall-logistics:operations:porter',
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:operations:admin',
      'store:manager:corporational',
      'store:manageroperations:corporational',
      'store:operations:corporational',
      'store:technical-support-staff:corporational',
      'store:technical-support-manager:corporational',
      'mall-logistics:manageroperations:highadmin',
      'mall-logistics:operations:muhaberat',
      'mall-logistics:mall-management:user',
      'mall-logistics:exceptional:user',
    ],
    category: "Teknik İşlemler"
  },
  {
    title: 'Eğitim',
    path: '/dashboard/tutorials',
    icon: icon('mdi:cast-tutorial'),
    permissions: [
      'mall:manager:mall-administration',
      'mall-logistics:manager:superadmin',
      'mall-logistics:accounting:accountant:',
      'mall-logistics:operations:highadmin',
      'mall-logistics:operations:porter',
      'mall-logistics:manager:highadmin',
      'mall-logistics:manager:porter',
      'mall-logistics:operations:admin',
      'store:manager:corporational',
      'store:manageroperations:corporational',
      'store:operations:corporational',
      'store:technical-support-staff:corporational',
      'store:technical-support-manager:corporational',
      'mall-logistics:manageroperations:highadmin',
      'mall-logistics:operations:muhaberat',
      'mall-logistics:mall-management:user',
      'mall-logistics:exceptional:user',
    ],
    category: "Teknik İşlemler"
  },
];

export default navConfig;
