// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#webpack-dev-server-client-overlay {
    display: none !important;
}

#webpack-dev-server-client-overlay-div {
    display: none !important;
}

 .rpv-core__inner-page {
     background-color: 'transparent';
 }

 .rpv-toolbar__center {
     flex-grow: 0.89;
 }

 .rpv-toolbar__right {
     display: none;
 }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;CAEC;KACI,+BAA+B;CACnC;;CAEA;KACI,eAAe;CACnB;;CAEA;KACI,aAAa;CACjB","sourcesContent":["#webpack-dev-server-client-overlay {\n    display: none !important;\n}\n\n#webpack-dev-server-client-overlay-div {\n    display: none !important;\n}\n\n .rpv-core__inner-page {\n     background-color: 'transparent';\n }\n\n .rpv-toolbar__center {\n     flex-grow: 0.89;\n }\n\n .rpv-toolbar__right {\n     display: none;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
