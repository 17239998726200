import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Typography,
  Button,
  Divider,
  FormControl,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import axios from 'axios';
import api from 'src/services/BaseService';
import { useLocalization } from 'src/context/LocalizationContext';
import { DatePicker } from '@mui/x-date-pickers';

export default function HandshakesReportPage() {
  const { language } = useLocalization();
  const mallID = localStorage.getItem('mallID');
  const [handshakeList, setHandshakeList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  // filters
  const [handshakeNo, setHandshakeNo] = useState('');
  const [requestNo, setRequestNo] = useState('');
  const [store, setStore] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [person, setPerson] = useState('');
  const [qrType, setQrType] = useState('');
  const [accepting, setAccepting] = useState('');
  const [direction, setDirection] = useState('');

  const TABLE_HEAD = [
    { id: 'project', label: 'Proje' },
    { id: 'createdDate', label: 'Tarih' },
    { id: 'handshakeNo', label: 'Handshake No' },
    { id: 'requestNo', label: 'Talep No' },
    { id: 'createdTime', label: 'Saat' },
    { id: 'store', label: 'Mağaza' },
    { id: 'quantity', label: 'Adet' },
    { id: 'vehicle', label: 'Hizmet' },
    { id: 'transportStaff', label: 'Taşıma Personeli' },
    { id: 'qrType', label: 'QR Tipi' },
    { id: 'accepting', label: 'Kabul Eden' },
    { id: 'totalQuantity', label: 'Toplam Paket Adedi' },
    { id: 'direction', label: 'Hareket Yönü' },
  ];

  const fetchHandshakes = async (start, end) => {
    setIsLoading(true); // Loading starts
    document.body.classList.add('loading'); // Add loading class to body
    try {
      const url = `https://system.easypointapi.com/handshakes-report-view-json.php?start=${start}&end=${end}&mall=${mallID}`;
      const response = await axios.get(url);
      const data = response.data;

      if (typeof data === 'string' && data.includes('Kayıt bulunamadı')) {
        setHandshakeList([]);
      } else {
        const formattedData = data.map((item) => ({
          project: item[0],
          createdDate: item[1]
            ? moment(item[1], 'MM-DD-YYYY').format('DD/MM/YYYY')
            : '',
          handshakeNo: item[2],
          requestNo: item[3],
          createdTime: item[4],
          store: item[5],
          quantity: item[6],
          vehicle: item[7],
          transportStaff: item[8],
          qrType: item[9],
          accepting: item[10],
          totalQuantity: item[11],
          direction: item[12],
        }));
        console.log('formatted Data: ', formattedData);
        setHandshakeList(formattedData);
        setAllData(formattedData);
      }
    } catch (error) {
      console.error(language.commons.errors.fetch_failed);
      setHandshakeList([]);
    } finally {
      setIsLoading(false); // Loading ends
      document.body.classList.remove('loading'); // Remove loading class
    }
  };

  const exportToExcel = () => {
    const tempData = handshakeList?.map((x, index) => ({
      Proje: x.project || '',
      Tarih: x.createdDate || '',
      'Handshake No': x.handshakeNo || '',
      'Talep No': x.requestNo || '',
      Saat: x.createdTime || '',
      Mağaza: x.store || '',
      Adet: x.quantity || '',
      Hizmet: x.vehicle || '',
      'Taşıma Personeli': x.transportStaff || '',
      'QR Tipi': x.qrType || '',
      'Kabul Eden': x.accepting || '',
      'Toplam Paket Adedi': x.totalQuantity || '',
      'Hareket Yönü': x.direction || '',
    }));

    api
      .post('excel/export', {
        type: 'excel',
        content: tempData,
        header: {
          Proje: 'string',
          Tarih: 'string',
          'Handshake No': 'string',
          'Talep No': 'string',
          Saat: 'string',
          Mağaza: 'string',
          Adet: 'string',
          Hizmet: 'string',
          'Taşıma Personeli': 'string',
          'QR Tipi': 'string',
          'Kabul Eden': 'string',
          'Toplam Paket Adedi': 'number',
          'Hareket Yönü': 'string',
        },
        sheetTitle: 'Handshake Raporu',
      })
      .then((response) => {
        if (response.data.status) {
          const newWindow = window.open(response.data.result, '_blank');
          if (
            !newWindow ||
            newWindow.closed ||
            typeof newWindow.closed === 'undefined'
          ) {
            alert(
              'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
            );
          }
        } else {
          console.error('EXCEL aktarma hatası:', response.data.message);
        }
      })
      .catch((error) => {
        console.error("EXCEL'e aktarılırken bir hata meydana geldi:", error);
      });
  };

  const handleFilter = () => {
    if (startDate && endDate) {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
      fetchHandshakes(formattedStartDate, formattedEndDate);
    }
  };

  const clearFilters = () => {
    setHandshakeNo('');
    setRequestNo('');
    setStore('');
    setVehicle('');
    setPerson('');
    setQrType('');
    setAccepting('');
    setDirection('');
    setHandshakeList(allData);
  };

  const handleFilterByFields = () => {
    let filteredData = allData;

    if (handshakeNo) {
      filteredData = filteredData?.filter((item) =>
        item.handshakeNo
          ?.toLocaleLowerCase()
          .includes(handshakeNo.toLocaleLowerCase())
      );
    }

    if (requestNo) {
      filteredData = filteredData?.filter((item) =>
        item.requestNo
          ?.toLocaleLowerCase()
          .includes(requestNo.toLocaleLowerCase())
      );
    }

    if (store) {
      filteredData = filteredData?.filter((item) =>
        item.store?.toLocaleLowerCase()?.includes(store.toLocaleLowerCase())
      );
    }

    if (vehicle) {
      filteredData = filteredData.filter((item) =>
        item.vehicle?.toLocaleLowerCase().includes(vehicle.toLocaleLowerCase())
      );
    }

    if (person) {
      filteredData = filteredData.filter((item) =>
        item.transportStaff
          ?.toLocaleLowerCase()
          .includes(person.toLocaleLowerCase())
      );
    }

    if (qrType) {
      filteredData = filteredData.filter((item) =>
        item.qrType?.toLocaleLowerCase().includes(qrType.toLocaleLowerCase())
      );
    }

    if (accepting) {
      filteredData = filteredData.filter((item) =>
        item.accepting
          ?.toLocaleLowerCase()
          .includes(accepting.toLocaleLowerCase())
      );
    }

    if (direction) {
      filteredData = filteredData.filter((item) =>
        item.direction
          ?.toLocaleLowerCase()
          .includes(direction.toLocaleLowerCase())
      );
    }

    setHandshakeList(filteredData);
  };

  useEffect(() => {
    const defaultStart = moment().subtract(1, 'month').format('YYYY-MM-DD');
    const defaultEnd = moment().format('YYYY-MM-DD');
    fetchHandshakes(defaultStart, defaultEnd);
  }, []);

  return (
    <>
      <Helmet>
        <title>Handshake Raporu | Mall Logistics Panel</title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }} spacing={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Handshake Raporu
          </Typography>
          {handshakeList?.length > 0 && (
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              Excel'e Aktar
            </Button>
          )}
        </Stack>

        <Card>
          <DynamicTable
            data={handshakeList}
            headers={TABLE_HEAD}
            initialOrder="Tarih"
            initalOrderDirection="desc"
          >
            <FilterViewButton
              variant="contained"
              onClick={() => setFilterModal(true)}
            />
          </DynamicTable>
        </Card>

        <CustomFilter
          modalType="drawer"
          filterModal={filterModal}
          handleCloseModal={() => setFilterModal(false)}
        >
          <Stack direction="column" spacing={2} p={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                label={language.commons.filter.start_date}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                label={language.commons.filter.end_date}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
            <Stack direction="row" spacing={2}>
              <ClearButton onClick={() => setHandshakeList([])} />
              <FilterButton onClick={handleFilter} />
            </Stack>
            <Divider />
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={'Handshake No'}
                variant="outlined"
                value={handshakeNo}
                onChange={(e) => setHandshakeNo(e.target.value)}
              />
            </FormControl>

            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={'Talep No'}
                variant="outlined"
                value={requestNo}
                onChange={(e) => setRequestNo(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={language.commons.store}
                variant="outlined"
                value={store}
                onChange={(e) => setStore(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={language.commons.vehicle_type}
                variant="outlined"
                value={vehicle}
                onChange={(e) => setVehicle(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={language.commons.column.transport_person}
                variant="outlined"
                value={person}
                onChange={(e) => setPerson(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={'QR Tipi'}
                variant="outlined"
                value={qrType}
                onChange={(e) => setQrType(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={'Kabul Eden'}
                variant="outlined"
                value={accepting}
                onChange={(e) => setAccepting(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                label={language.commons.direction}
                variant="outlined"
                value={direction}
                onChange={(e) => setDirection(e.target.value)}
              />
            </FormControl>
            <Stack direction="row" spacing={2}>
              <ClearButton onClick={clearFilters} />
              <FilterButton onClick={handleFilterByFields} />
            </Stack>
          </Stack>
        </CustomFilter>
      </Stack>
    </>
  );
}
