import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Typography } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { tokenService } from 'src/services';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {


  //HARDCODED

  // const isUserAllowedForSafeBox = tokenService?.getUser()?.authLocations?.mall?.some(x => x.id === "61973122632916808660" || x.id === "52248884542741429211" || x.id === "74278142814084507988");

  // Eğer isUserAllowedForSafeBox false ise, belirtilen title'lara sahip olan objeleri data'dan çıkartıyoruz.
  // let filteredData = isUserAllowedForSafeBox
  //   ? data
  //   : data.filter(item => item.title !== "Emanet Dolap Raporu" && item.title !== "Dolap İşlemleri" && item.title !== "Vestiyer İşlemleri");


  const generateCombinedPermissions = (
    accountCategory,
    frontAuthArray,
    userType
  ) => {
    return frontAuthArray.map(
      (frontAuth) => `${accountCategory}:${frontAuth}:${userType}`
    );
  };

  const groupByCategory = (data) => {
    return data.reduce((groups, item) => {
      const category = item.category || '';
      groups[category] = groups[category] || [];
      groups[category].push(item);
      return groups;
    }, {});
  };

  useEffect(() => {
    const user = tokenService.getUser();
    const accountCategory = user?.accountCategory || '';
    const userType = user?.type;
    const frontAuthArray = user?.frontAuth || [];

    // console.log(
    //   generateCombinedPermissions(accountCategory, frontAuthArray, userType)
    // );
  }, []);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {Object.entries(groupByCategory(data)).map(([category, items]) => {
          const user = tokenService.getUser();
          const accountCategory = user?.accountCategory || '';
          const userType = user?.type;
          const frontAuthArray = user?.frontAuth || [];

          const combinedPermissions = generateCombinedPermissions(
            accountCategory,
            frontAuthArray,
            userType
          );


          const hasCategoryPermission = items.some((item) =>
            item.permissions.some((permission) =>
              combinedPermissions.includes(permission)
            )
          );

          if (!hasCategoryPermission) {
            return null;
          }

          return (
            <React.Fragment key={category}>
              {category && category !== "Emanet İşlemleri" && (
                <Typography
                  sx={{
                    color: '#98ABEA',
                    fontSize: 12,
                    marginLeft: '20px',
                    fontWeight: '600',
                    marginBlock: '5px',
                  }}
                >
                  {category}
                </Typography>
              )}
              {items.map((item, index) => {
                const hasPermission = item.permissions.some((permission) =>
                  combinedPermissions.includes(permission)
                );

                // Emanet İşlemleri için özel Accordion yapısı
                if ((item.accordion) && index === 0 && hasPermission) {
                  return (
                    <Accordion key={index} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#E7EAFA" }} />}
                        sx={{
                          display: "flex",
                          alignItems: "center", // Dikey hizalama
                          justifyContent: "flex-start", // Yatayda sola hizalama
                          color: "#E7EAFA",
                          fontSize: 14,
                          paddingLeft: "0px", // Sola boşluk eklemek için
                          minHeight: '48px',
                          '&.Mui-expanded': {
                            color: "#E7EAFA",
                            height: '48px',
                            minHeight: '40px',
                            alignItems: 'center',
                            display: 'flex'
                          },
                        }}
                      >
                        <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>
                        <ListItemText
                          disableTypography
                          primary={item.category}
                          sx={{ textAlign: "left", marginLeft: "0px" }} // Başlık metnini sola hizala
                        />
                      </AccordionSummary>

                      <AccordionDetails>
                        {data
                          .filter((subItem) => subItem.category === item.category)
                          .map((subItem, subIndex) => (
                            <NavItem key={subIndex} item={subItem} />
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                }

                // Diğer tüm izinli başlıklar
                if (hasPermission && !item.accordion) {
                  return <NavItem key={index} item={item} />;
                }

                return null;
              })}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: '#E7EAFA',
        '&.active': {
          color: '#E7EAFA',
          bgcolor: '#0666F6',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText
        disableTypography
        primary={
          tokenService?.getUser()?.accountCategory === 'store' &&
            title === 'Hands Free'
            ? tokenService?.getUser()?.authLocations?.branch[0]?.mallID ===
              '98569271082166328155'
              ? 'Bags-Free'
              : 'Hands Free'
            : title
        }
      />
      {info && info}
    </StyledNavItem>
  );
}
