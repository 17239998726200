import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
// @mui
import {
  Card,
  Stack,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Divider,
  useMediaQuery,
  Switch,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//shared
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import EditButton from 'src/components/shared/EditButton';
import DynamicTable from 'src/components/shared/DynamicTable';
// sections
import Services from 'src/services/Services';
import './muhaberatOutput.css';
import SupportTicketsConversation from './Views/SupportTicketsConversation';
import { tokenService } from 'src/services';
import { useParams } from 'react-router-dom';
import SupportTicketsCreateTicket from './Views/SupportTicketsCreateTicket';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';

// ----------------------------------------------------------------------

export default function SupportTickets() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { language } = useLocalization();

  const { id } = useParams();

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [fullName, setFullName] = useState('');
  const [title, setTitle] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [username, setUsername] = useState('');
  const [status, setStatus] = useState([]);

  const [seeDetails, setSeeDetails] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();

  const [currentUser, setCurrentUser] = useState();

  const [openCreateTicket, setOpenCreateTicket] = useState(false);

  const [userTickets, setUserTickets] = useState([]);
  const [systemTickets, setSystemTickets] = useState([]);
  const [showSystemTickets, setShowSystemTickets] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);
  const handleModal = () => {
    setFilterModal(true);
  };
  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const TICKET_TABLE_HEAD = [
    {
      id: 'status',
      label: language.commons.status,
      alignRight: false,
      format: (value) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {value === '1' ? (
            <span
              style={{
                backgroundColor: '#ABEFC6',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : value === '2' ? (
            <span
              style={{
                backgroundColor: '#FEA3B4',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : value === '4' ? (
            <span
              style={{
                backgroundColor: '#9155FD',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : value === '3' || value === '5' ? (
            <span
              style={{
                backgroundColor: '#FFD6AE',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : null}
        </div>
      ),
    },
    {
      id: 'nameLastname',
      label: language.commons.column.name_surname,
      alignRight: false,
      sortBy: 'name',
      format: (value, row) => `${row.name} ${row.lastname}`,
    },
    {
      id: 'createdDateTime',
      label: language.commons.column.created_date,
      alignRight: false,
      format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      id: 'title',
      label: language.commons.column.title,
      alignRight: false,
      format: (value) => truncatedText(value),
    },
    {
      id: 'type',
      label: language.commons.column.kind_of,
      alignRight: false,
      format: (value) => getTitleFromValue(value),
    },
    {
      id: 'username',
      label: language.commons.labels.username,
      alignRight: false,
    },

    {
      id: 'edit',
      label: '',
      alignRight: false,
      format: (value, row) => (
        <EditButton tooltip='İncele' icon={'ph:eye'} onClick={() => handleOpenDetails(row)} />
      ),
    },
  ];

  const supportTypes = [
    { value: 'MallLogisticsNet', title: language.commons.supportTypes.mallLogisticsNet },
    { value: 'Mall Logistics App', title: language.commons.supportTypes.mallLogisticsApp },
    { value: 'Handsfree Store Panel', title: language.commons.supportTypes.handsfreeStorePanel },
    { value: 'Handsfree Point Panel', title: language.commons.supportTypes.handsfreePointPanel },
    { value: 'General', title: language.commons.supportTypes.general },
    { value: 'Porter App', title: language.commons.supportTypes.porterApp },
    { value: 'Technical Support', title: language.commons.supportTypes.technicalSupport },
    { value: 'Development', title: language.commons.supportTypes.development },
    { value: 'Report Bug', title: language.commons.supportTypes.reportBug },
  ];

  const statusTypes = [
    { value: '1', label: language.commons.statusTypes.finish }, //yeşil
    { value: '2', label: 'İptal Edildi' }, //kırmızı
    { value: '3', label: language.commons.statusTypes.pending }, //sarı
    { value: '4', label: language.commons.statusTypes.inProgress }, //mor
    { value: '5', label: language.commons.statusTypes.awaitingResponse }, //fuşya,lila gradient
  ];

  const getStatusFromValue = (value) => {
    const statusType = statusTypes.find((type) => type.value === value);
    return statusType ? statusType.label : null;
  };

  const getTitleFromValue = (value) => {
    const supportType = supportTypes.find((type) => type.value === value);
    return supportType ? supportType.title : null;
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function truncatedText(text) {
    const maxLength = 20;
    if (text.length > maxLength) {
      return <span>{text.substring(0, maxLength)}...</span>;
    }
    return <span style={{ width: '35%' }}>{text}</span>;
  }

  const handleResponse = (response) => {
    const { status, result } = response.data;
    if (status) {
      const filteredUserTickets = result.filter(ticket => ticket.email !== 'sistem@malllogistics.com');
      const filteredSystemTickets = result.filter(ticket => ticket.email === 'sistem@malllogistics.com');

      setUserTickets(filteredUserTickets);
      setSystemTickets(filteredSystemTickets);
      setOriginalData(result)
    } else {
      setUserTickets([]);
      setSystemTickets([]);
      setOriginalData([])
      toast.error('Destek talebi bulunamadı.');
    }
    setIsLoading(false);
  };

  const handleSwitchChange = () => {
    setShowSystemTickets((prev) => !prev); // Switch butonuna tıklayınca liste değişimi
    let sysTickets = originalData.filter(ticket => ticket.email === 'sistem@malllogistics.com');
    let usrTickets = originalData.filter(ticket => ticket.email !== 'sistem@malllogistics.com');
    setSystemTickets(sysTickets)
    setUserTickets(usrTickets)
    clearFilters()
  };

  const displayedTickets = showSystemTickets ? systemTickets : userTickets;

  const handleError = () => {
    setIsLoading(false);
    toast.error('Bir hata oluştu.');
  };

  const fetchTickets = (serviceFunction, ...args) => {
    serviceFunction(...args)
      .then(handleResponse)
      .catch(handleError);
  };

  const getTickets = () => {
    const currentUserInfo = tokenService.getUser();
    setCurrentUser(currentUserInfo);

    const { frontAuth, userID, type } = currentUserInfo;
    const { token } = parsedUser;
    if (type === 'superadmin' || frontAuth.includes('technical-support-staff') || frontAuth.includes('technical-support-manager')) {
      setShowSwitch(true)
    }
    if (frontAuth) {
      if (type === 'superadmin') {
        fetchTickets(Services.GetTicketsManager, token);
      } else if (
        !frontAuth.includes('technical-support-staff') &&
        !frontAuth.includes('technical-support-manager')
      ) {
        fetchTickets(Services.GetTicketsUser, token, userID);
      } else if (
        frontAuth.includes('technical-support-staff') &&
        !frontAuth.includes('technical-support-manager')
      ) {
        fetchTickets(Services.GetTicketsStaff, token, userID);
      } else if (frontAuth.includes('technical-support-manager')) {
        fetchTickets(Services.GetTicketsManager, token);
      }
    } else {
      if (type === 'superadmin') {
        fetchTickets(Services.GetTicketsManager, token);
      } else {
        fetchTickets(Services.GetTicketsUser, token, userID);
      }
    }
  };

  function getTicket() {
    Services.GetTicket(parsedUser.token, id)
      .then((response) => {
        if (response.data.status) {
          setSelectedTicket(response.data.result);
          setSeeDetails(true);
        } else {
          toast.error('Destek talebi bulunamadı.');
        }
      })
      .catch(() => {
        toast.error('Bir hata oluştu.');
      });
  }

  useEffect(() => {
    if (!id) {
      getTickets();
    } else {
      getTicket();
    }
  }, []);

  const handleChangePlatform = (event) => {
    const {
      target: { value },
    } = event;
    setPlatforms(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const getFilteredTicketList = () => {


    let filterableData = []

    if (showSystemTickets) {
      filterableData = originalData.filter(ticket => ticket.email === 'sistem@malllogistics.com');
    }
    else {
      filterableData = originalData.filter(ticket => ticket.email !== 'sistem@malllogistics.com');
    }

    let filteredTicketList;
    if (
      fullName ||
      (startDate && endDate) ||
      title ||
      platforms.length > 0 ||
      status.length > 0 ||
      username
    ) {
      filteredTicketList = filterableData.filter((item) => {
        const nameMatch = fullName
          ? (item.name + ' ' + item.lastname)
            .toLowerCase()
            .includes(fullName.toLowerCase())
          : true;
        const usernameMatch = username
          ? item.username.toLowerCase().includes(username.toLowerCase())
          : true;
        const dateMatch =
          startDate && endDate
            ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
            moment(startDate).format('DD-MM-YYYY') &&
            moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
            moment(endDate).format('DD-MM-YYYY')
            : true;
        const titleMatch = title
          ? item.title.toLowerCase().includes(title.toLowerCase())
          : true;
        const platformMatch =
          platforms.length > 0 ? platforms.includes(item.type) : true;
        const statusMatch =
          status.length > 0 ? status.includes(item.status) : true;

        return (
          dateMatch &&
          nameMatch &&
          titleMatch &&
          platformMatch &&
          statusMatch &&
          usernameMatch
        );
      });
    } else {
      filteredTicketList = filterableData;
    }

    if (showSystemTickets) {
      setSystemTickets(filteredTicketList)
    }
    else {
      setUserTickets(filteredTicketList)
    }
  };

  const handleOpenDetails = (ticket) => {
    setSeeDetails(true);
    setSelectedTicket(ticket);
  };

  const handleCloseDetails = () => {
    setSeeDetails(false);
    setSelectedTicket();
    getTickets();
  };

  const clearFilters = () => {
    let sysTickets = originalData.filter(ticket => ticket.email === 'sistem@malllogistics.com');
    let usrTickets = originalData.filter(ticket => ticket.email !== 'sistem@malllogistics.com');
    setSystemTickets(sysTickets)
    setUserTickets(usrTickets)

    setFullName('');
    setStartDate(null);
    setEndDate(null);
    setTitle('');
    setPlatforms([]);
    setStatus([]);
    setUsername('');
  };

  if (seeDetails) {
    return (
      <SupportTicketsConversation
        ticket={selectedTicket}
        handleCloseDetails={handleCloseDetails}
        getTickets={getTickets}
        seeDetails={seeDetails}
        id={id}
        token={parsedUser.token}
      />
    );
  } else if (openCreateTicket) {
    return (
      <SupportTicketsCreateTicket
        currentUser={currentUser}
        token={parsedUser.token}
        setOpenCreateTicket={setOpenCreateTicket}
        handleCloseDetails={handleCloseDetails}
      />
    );
  } else {
    return (
      <>
        <Helmet>
          <title> {language.support_ticket.titles.site_title} </title>
        </Helmet>

        <Stack style={{ paddingInline: '25px' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {language.support_ticket.titles.title}
            </Typography>
            <AddEntityButton
              onClick={() => setOpenCreateTicket(true)}
              label={language.support_ticket.titles.create_req}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent={isMobile ? 'space-between' : 'end'}
            mb={5}
          >

            {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}
          </Stack>
          <Card>
            <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={handleCloseModal}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                paddingY={2}
                paddingX={1}
                spacing={2}

              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label={language.commons.labels.email_username}
                    variant="outlined"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label={language.commons.labels.username}
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label={language.commons.labels.subject}
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>




                <Stack
                  direction="row"
                  width='100%'
                  spacing={1}
                >
                  <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                    <InputLabel id="priority-store-label">{language.commons.labels.platform}</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={platforms}
                      onChange={handleChangePlatform}
                      input={<OutlinedInput label="Platform" />}
                      MenuProps={MenuProps}
                    >
                      {supportTypes.map((type) => (
                        <MenuItem
                          key={type.value}
                          value={type.value}
                          style={getStyles(type.title, supportTypes, theme)}
                        >
                          {type.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                    <InputLabel id="priority-store-label">{language.commons.labels.req_status}</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={status}
                      onChange={handleChangeStatus}
                      input={<OutlinedInput label={language.commons.labels.req_status} />}
                      MenuProps={MenuProps}
                    >
                      {statusTypes.map((type) => (
                        <MenuItem
                          key={type.value}
                          value={type.value}
                          style={getStyles(type.label, statusTypes, theme)}
                        >
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                >
                  <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={language.commons.labels.start_date}
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        sx={{ flex: 1 }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={language.commons.labels.end_date}
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        sx={{ flex: 1 }}
                      />
                    </LocalizationProvider>
                  </FormControl>


                </Stack>
              </Stack>
              <Divider sx={{ width: '100%', mb: 2 }} />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <ClearButton onClick={clearFilters} />
                <FilterButton onClick={getFilteredTicketList} />
              </Stack>
            </CustomFilter>

            {showSwitch &&
              // <Stack direction="row" alignItems="center" justifyContent="flex-end" marginRight={3} marginLeft={3} marginTop={2} spacing={2}>
              //   <span>Kullanıcı Talepleri</span>
              //   <Switch
              //     checked={showSystemTickets}
              //     onChange={handleSwitchChange}
              //     color="primary"
              //   />
              //   <span>Sistem Hataları</span>
              // </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                marginRight={3}
                marginLeft={3}
                marginTop={2}
                spacing={2}
              >
                <ToggleButtonGroup
                  exclusive
                  value={showSystemTickets ? "system" : "user"}
                  onChange={handleSwitchChange}
                  aria-label="toggle ticket type"
                >
                  <ToggleButton
                    value="user"
                    selected={!showSystemTickets}
                    onClick={() => !showSystemTickets && handleSwitchChange()}
                  >
                    Kullanıcı Talepleri
                  </ToggleButton>
                  <ToggleButton
                    value="system"
                    selected={showSystemTickets}
                    onClick={() => showSystemTickets && handleSwitchChange()}
                  >
                    Sistem Hataları
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            }
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  padding: '50px',
                }}
              >
                <CircularProgress size={24} color="inherit" />
              </div>
            ) : (
              <DynamicTable
                data={displayedTickets}
                headers={TICKET_TABLE_HEAD}
                initialOrder={'createdDateTime'}
                initalOrderDirection={'desc'}
                isOrdered={true}
                rowOnClick={(row) => handleOpenDetails(row)}
                isMobile={isMobile}
                handleModal={handleModal}
                handleCloseModal={handleCloseModal}
              />
            )}

          </Card>
        </Stack>
      </>
    );
  }
}
