import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Button,
  ClickAwayListener,
  Stack,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/iconify';
import { tokenService } from 'src/services';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCustomerValue } from 'src/store/customer';
import api from 'src/services/BaseService';
import packageJson from '../../../../package.json';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------_

export default function Searchbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCustomerStatus, setSelectedCustomerStatus] = useState([]);
  const [branchesAndMallsIds, setBranchesAndMallsIds] = useState([]);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log('value: ', value);
    if (
      tokenService.getUser()?.accountCategory === 'mall-logistics' ||
      tokenService.getUser()?.accountCategory === 'mall'
    ) {
      // mall logistics veya mall ise
      localStorage.setItem('mallID', value.id);
      localStorage.setItem('branchID', '');
      localStorage.setItem('easypointID', value?.points?.malkabul?.id);
      dispatch(updateCustomerValue(value));
      setSelectedCustomerStatus(value);
      api
        .post('flow/malls-get-default-transportation-tools', {
          mallID: value.id,
        })
        .then((res) => {
          console.log(res.data.result, 'Vehicles');
          // console.log('malls vehicles: ', res.data);
          if (res.data.status) {
            localStorage.setItem(
              'mallsTransportationTools',
              JSON.stringify(res.data.result)
            );
          } else {
            localStorage.setItem('mallsTransportationTools', []);
          }
        })
        .catch((err) => {
          // console.log(err);
          localStorage.setItem('mallsTransportationTools', []);
        })
        .finally(() => window.location.reload());
    } else if (tokenService.getUser()?.accountCategory === 'company') {
      localStorage.setItem('mallID', value.id);
      localStorage.setItem('easypointID', value?.points?.malkabul?.id);

      const matchedBranch = branchesAndMallsIds.find(
        (item) => item.mallID === value.id
      );
      new Promise((resolve, reject) => {
        if (matchedBranch) {
          console.log('matchedBranch: ', matchedBranch);
          localStorage.setItem('branchID', matchedBranch.branchID);
          dispatch(
            updateCustomerValue({
              id: value.id,
              title: value.title,
              branchID: matchedBranch.branchID,
              mallTitle: null,
            })
          );
          setSelectedCustomerStatus(value);
          resolve();
        } else {
          toast.error("Seçtiğiniz AVM'de mağazanız bulunamadı.");
          reject();
        }
      }).finally(() => {
        window.location.reload();
      });
    } else {
      // store ise
      localStorage.setItem('mallID', value.mallID);
      localStorage.setItem('branchID', value.branchID);
      localStorage.setItem('easypointID', value?.mall[0]?.points?.malkabul?.id);
      console.log('value: ', value);
      dispatch(
        updateCustomerValue({
          id: value.branchID,
          title: value.title,
          mallTitle: value.mall[0].title,
          branchID: value.branchID,
        })
      );
      setSelectedCustomerStatus({
        id: value.branchID,
        title: value.title,
        mallTitle: value.mall[0].title,
      });
      api
        .post('flow/malls-get-default-transportation-tools', {
          mallID: value.mallID,
        })
        .then((res) => {
          console.log(res.data.result, 'Vehicles');
          // console.log('malls vehicles: ', res.data);
          if (res.data.status) {
            localStorage.setItem(
              'mallsTransportationTools',
              JSON.stringify(res.data.result)
            );
          } else {
            localStorage.setItem('mallsTransportationTools', []);
          }
        })
        .catch((err) => {
          // console.log(err);
          localStorage.setItem('mallsTransportationTools', []);
        })
        .finally(() => window.location.reload());
    }
  };

  useEffect(() => {
    let customer;
    const mallID = localStorage.getItem('mallID');
    const branchID = localStorage.getItem('branchID');
    const branchesAndMalls = [];

    if (tokenService.getUser()?.accountCategory === 'company') {
      customer = tokenService
        .getUser()
        ?.authLocations?.mall.find((item) => item?.id === mallID);

      tokenService.getUser().authLocations.branch.forEach((item) => {
        branchesAndMalls.push({
          branchID: item.branchID,
          mallID: item.mallID,
        });
      });

      setBranchesAndMallsIds(branchesAndMalls);
    } else {
      if (tokenService.getUser()?.authLocations?.branch) {
        if (tokenService.getUser()?.authLocations?.branch?.length > 0) {
          // customer = tokenService.getUser()?.authLocations?.branch[0];
          customer = tokenService
            .getUser()
            ?.authLocations?.branch.find((item) => item.branchID === branchID);
        } else {
          customer = tokenService.getUser()?.authLocations?.branch['0'];
        }
      } else {
        if (tokenService.getUser()?.authLocations?.mall?.length > 0) {
          customer = tokenService
            .getUser()
            ?.authLocations?.mall.find((item) => item?.id === mallID);
        } else {
          customer = tokenService.getUser()?.authLocations?.mall['0'];
        }
      }
      // son aşama
    }

    if (tokenService.getUser()?.accountCategory === 'store') {
      customer.mallTitle = customer.mall[0]?.title;
    }

    setSelectedCustomerStatus(customer);
    dispatch(
      updateCustomerValue({
        id:
          tokenService.getUser()?.accountCategory === 'store'
            ? customer?.branchID
            : customer?.id,
        title: customer?.title,
        mallTitle:
          tokenService.getUser()?.accountCategory === 'store'
            ? customer.mall[0]?.title
            : null,
        branchID:
          tokenService.getUser()?.accountCategory === 'company' ? branchID : '',
      })
    );
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Stack
          mr={1}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <FormControl
            sx={{ flexDirection: 'row', height: 50, alignItems: 'center' }}
          >
            <Select
              sx={{ marginRight: '8px', minWidth: 250 }}
              displayEmpty
              value={selectedCustomerStatus}
              input={<OutlinedInput />}
              onChange={handleChange}
              renderValue={(selected) => {
                return `${selected?.title} ${
                  (selected?.mallTitle !== null ||
                    selected?.mallTitle !== '') &&
                  selected?.mallTitle
                    ? '(' + selected?.mallTitle + ')'
                    : ''
                }`;
              }}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {tokenService.getUser()?.accountCategory === 'store' &&
              tokenService.getUser()?.authLocations?.branch ? (
                tokenService.getUser()?.authLocations?.branch?.length > 0 ? (
                  tokenService
                    .getUser()
                    ?.authLocations?.branch.slice() // Dizi kopyası
                    .sort((a, b) => a.title.localeCompare(b.title)) // A'dan Z'ye sıralama
                    .map((x, index) => (
                      <MenuItem key={index} value={x}>
                        {x?.title} ({x.mall[0].title})
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem
                    key={tokenService.getUser()?.authLocations?.branch.id}
                    value={tokenService.getUser()?.authLocations?.branch}
                  >
                    {tokenService.getUser()?.authLocations?.branch?.title}
                  </MenuItem>
                )
              ) : tokenService.getUser()?.authLocations?.mall?.length > 0 ? (
                tokenService
                  .getUser()
                  ?.authLocations?.mall.slice() // Dizi kopyası
                  .sort((a, b) => a.title.localeCompare(b.title)) // A'dan Z'ye sıralama
                  .map((x, index) => (
                    <MenuItem key={index} value={x}>
                      {x?.title}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem
                  key={tokenService.getUser()?.authLocations?.branch?.id}
                  value={tokenService.getUser()?.authLocations?.branch}
                >
                  {tokenService.getUser()?.authLocations?.branch?.title}
                </MenuItem>
              )}
            </Select>
            {tokenService.getUser()?.accountCategory !== 'mall' &&
              tokenService.getUser()?.frontAuth.includes('operations') && (
                <>
                  {isMobile ? null : (
                    <Button
                      variant="contained"
                      sx={{ height: '50px' }}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() =>
                        navigate('/dashboard/finished-jobs', {
                          state: {
                            showCreateModal: true,
                          },
                        })
                      }
                    >
                      İş Emri Oluştur
                    </Button>
                  )}
                </>
              )}

            {isMobile ? null : (
              <Typography sx={{ color: '#b5b5b5', marginLeft: '20px' }}>
                Versiyon: {packageJson.version}
              </Typography>
            )}
          </FormControl>
        </Stack>
      </div>
    </ClickAwayListener>
  );
}
